import React, { useState } from 'react'

// DATA

// clients
import {app} from '../firebase'

import { 
  getAuth, 
  signInWithEmailAndPassword, 
  GoogleAuthProvider, 
  signInWithPopup,
} from "firebase/auth";
// queries
import { 
  getMember,
} from '../queries/memberQueries'

import {
  getSite,
} from '../queries/siteQueries'

// UI

// components
import SignInPage from '../components/Pages/SignInPage'

export default function SignIn({history}){
  const auth = getAuth(app);

  const provider = new GoogleAuthProvider();

  // state
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ alert, setAlert ] = useState('')
  const [ loading, setLoading] = useState(false)

  const handleEmail = event => setEmail(event.target.value)
  const handlePassword = event => setPassword(event.target.value)

  const inputs = [
    {
      name:'email',
      label: 'Email',
      handleFunc: handleEmail,
      value: email
    },
    {
      name:'password',
      label: 'Password',
      handleFunc: handlePassword,
      value: password
    }
  ]

  // functions
  const checkUser = async (fbuser) => {
   
    try{
      const user1 = await getMember(fbuser.uid, fbuser.accessToken)

      const sites = await getSite(fbuser.accessToken)

      if(sites===null || sites.length===0){
        return history.push('/create_site')
      }

      const user = user1[0]

      if(!user){ 
        setAlert('You do not have access.')
        resetInput()
        return
      }
      if(user.role==='deactivated'){ 
        setAlert('Account is no longer activated.')
        resetInput()
        return
      }
      if(user.role==='new'){ 
        setAlert('Your account needs to be assigned a role by the site administator')
        resetInput()
        return
      }

      localStorage.setItem('email', user.email)
      localStorage.setItem('name', user.name)
      localStorage.setItem('role', user.role)
      localStorage.setItem('author_id', user.id)
      return history.push('/user/posts')
     } catch(error){

       resetInput()
       setAlert('You do not have access.')
    }
  }

  const resetInput = () => {
    setEmail('')
    setPassword('')
  }

  const loginPassword = async () => {
    try {
      setLoading(true)
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      const user = userCredential.user;
   
      if(user){
        checkUser(user)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const googleLogin = async () => {
   
    try {
      setLoading(true)
      const userCredential = await signInWithPopup(auth, provider)
      const user = userCredential.user;
  
      if(user){
        checkUser(user)
      }
    } catch (error) {
      setAlert(error.message)
    } finally {
      setLoading(false)
    }
  }

  return <SignInPage
          inputs={inputs} 
          loginPassword={loginPassword} 
          googleLogin={googleLogin}
          alert={alert}
          loading={loading}
        />
}