import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Grid, Divider, IconButton, Typography, Box, Tooltip } from '@mui/material'
import { Delete, Close, Edit } from '@mui/icons-material';
import { grey } from '@mui/material/colors'



const TagItem = ({
  id,
  tag_name, 
  tag_color,
  deleteTag,
  chooseEditTag,
}) => {
  const editT = () => chooseEditTag(id)
  const deleteT = () => deleteTag(id)
  return(
              <Grid  item >
                <Box sx={{
                  border: 1, 
                  borderRadius: 2,
                  borderColor: grey[400]
                }}>
               <Grid container spacing={1} alignItems='center' sx={{p: 1}} >
                <Grid item >
                  <Box style={{
                      backgroundColor: tag_color, 
                      height: 20, 
                      width: 20, 
                      borderRadius: 5,
                    }}
                  />
                </Grid>
                <Grid item >
                  <Typography sx={{mr:1}}>
                    {tag_name}
                  </Typography>
                </Grid>
                <Grid item >
                  <Tooltip title="Edit Tag">
                    <IconButton size='small' color='primary' onClick={editT}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item >
                  <Tooltip title="Delete Tag">
                    <IconButton size='small' color='error' onClick={deleteT}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
                
              </Grid>
            </Box>
          </Grid>  
  )
}

export default function TagsDialog({
    tagOpen,
    toggleTagOpen,
    deleteTag,
    chooseEditTag,
    tags,
}) {
  
    return (
      <Dialog open={tagOpen} onClose={toggleTagOpen}>
        <DialogTitle>
          Tags
          <IconButton
            aria-label="close"
              onClick={toggleTagOpen}
              sx={{
                position: 'absolute',
                right: 10,
                top: 10,
                color: (theme) => theme.palette.grey[500],
              }}
            >
          <Close />
        </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={1} alignItems='center' >
            {tags.map(t => 
              <TagItem key={t.id} {...t}  deleteTag={deleteTag} chooseEditTag={chooseEditTag} />
            )}
          </Grid>  
        </DialogContent>    
      </Dialog>
  )
}
