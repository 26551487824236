import React  from 'react'
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

// import { ReactQueryDevtools } from 'react-query/devtools'

const container = document.getElementById('root')
const root = createRoot(container); 

root.render(
            <Router>
              <Route path="/" component={App} />
            </Router>
     )

serviceWorker.unregister()
