import * as React from 'react';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

import MainAuthor from '../Blog/MainAuthor';
import {SidebarPlain} from '../Blog/SideBar';
import BlogHeader from '../Blog/BlogHeader'
import Footer from '../Blog/Footer'

export default function AuthorPage({
  posts,
  site,
  tags,
  page_num,
  auth_id,
  total_count,
}) {
      const siteName = site ? site.site_name : ''
      const siteLogo = site ? site.site_logo : ''
      const siteDescription = site ? site.description : ''
      const siteTwitter = site ? site.twitter : ''
      const siteFacebook = site ? site.facebook : ''
      const adminEmail = site ? site.admin_email : ''
      const siteinstagram = site ? site.instagram : ''
      const sitediscord = site ? site.discord : ''
      const siteyoutube = site ? site.youtube : ''
      return  <Container maxWidth="lg">
                <BlogHeader 
                  siteName={siteName} 
                  siteLogo={siteLogo}
                  tags={tags}
                />
                <Divider sx={{mt: 2, mb: 2}} /> 
                <Grid container spacing={2}  >
                  <MainAuthor
                    page_num={page_num}
                    auth_id={auth_id}
                    total_count={total_count}
                    posts={posts} 
                  />
                  <SidebarPlain
                    siteDescription={siteDescription}
                    siteTwitter={siteTwitter}
                    siteFacebook={siteFacebook}
                    siteinstagram={siteinstagram}
                    sitediscord={sitediscord}
                    siteyoutube={siteyoutube}
                    adminEmail={adminEmail}
                  />
                </Grid>
                <Footer siteName={siteName} /> 
              </Container>
}