import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

// UI

// mui components
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'

import EditIcon from '@mui/icons-material/Edit'

// custom components
import LoadingIconButton from '../Buttons/LoadingIconButton'

export default function UpdateSite({
  siteName1,
  description1, 
  adminEmail1,
  handleSiteName,
  handleAdminEmail,
  handleDescription,
  updateSite,
  loading,
  handleSiteLogo,
  siteLogo,
  baseUrl,
  handleBaseUrl,
}){

    return(
    <Card>
      <CardContent>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant={'h5'} color='primary'>
              Site
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Link 
                    variant='body1'
                    component={RouterLink} 
                    to='/user/terms' 
                    color='primary'
                >
                    Terms of Service
                </Link>
              </Grid>
              <Grid item>
                <Link 
                    variant='body1'
                    component={RouterLink} 
                    to='/user/privacy' 
                    color='primary'
                >
                  Privacy Policy
                </Link>
                
              </Grid>
            </Grid>
              
            </Grid>
          </Grid>
       
      </CardContent>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <CardContent>
        <TextField 
          required
          value={siteName1} 
          onChange={handleSiteName} 
          sx={{ width: '100%'}}
          label='Name'
        />
      </CardContent>
      <CardContent>
        <TextField 
          multiline
          required
          value={description1} 
          onChange={handleDescription} 
          sx={{ width: '100%'}}
          label='Site Description'
        />
      </CardContent>
      <CardContent>
        <TextField 
          value={adminEmail1} 
          onChange={handleAdminEmail} 
          sx={{ width: '100%'}}
          label='Admin Email'
          required
        />
      </CardContent>
      <CardContent>
        <TextField 
          value={siteLogo} 
          onChange={handleSiteLogo} 
          sx={{ width: '100%'}}
          label='Site Logo'
          required
        />
      </CardContent>
      <CardContent>
        <TextField 
          value={baseUrl} 
          onChange={handleBaseUrl} 
          sx={{ width: '100%'}}
          label='Site Base Url'
          required
        />
      </CardContent>
      {/* <CardContent>
        <TextField 
          value={twitter} 
          onChange={handleTwitter} 
          sx={{ width: '100%'}}
          label='Twitter'
        />
      </CardContent>
      <CardContent>
        <TextField 
          value={facebook} 
          onChange={handleFacebook} 
          sx={{ width: '100%'}}
          label='Facebook'  
        />
      </CardContent>

      <CardContent>
        <TextField 
          value={instagram} 
          onChange={handleInstagram} 
          sx={{ width: '100%'}}
          label='Instagram'  
        />
      </CardContent>

      <CardContent>
        <TextField 
          value={discord} 
          onChange={handleDiscord} 
          sx={{ width: '100%'}}
          label='Discord'  
        />
      </CardContent>

      <CardContent>
        <TextField 
          value={tiktok} 
          onChange={handleTiktok} 
          sx={{ width: '100%'}}
          label='Tiktok'  
        />
      </CardContent> */}

      {/* <CardContent>
        <TextField 
          value={youtube} 
          onChange={handleYoutube} 
          sx={{ width: '100%'}}
          label='Youtube'  
        />
      </CardContent> */}


      <CardContent>
        <LoadingIconButton
          loading={loading}
          icon={<EditIcon />}
          onClick={updateSite} 
          title='Submit'
        />
      </CardContent> 
    </Card>
  )
}