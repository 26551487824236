import { postCF } from "../utils"

export const createMember =  async ({
    email,
    role,
    accessToken,
}) => {

    const newUser = {
      email,
      role,
    }

    try {
      return await postCF(`/create_profile`, accessToken, newUser)
    } catch(error){
      throw error
    }
}

export const updateMember = async (update, token) => {
  try {
    return await postCF(`/update_profile`, token, update)
  } catch(error){
    throw error
  }
}

export const updateRole = async (update, token) => {
  try {
   // await updateFBRole(update.uid)
    return await postCF(`/update_profile`, token, update)
  } catch(error){
    throw error
  }
}

export const getMember = async (uid, token) => {

    try {
      return await postCF(`/profile`, token, {uid})
    } catch(error){
      throw error
    }
}
