import React, {useEffect, useState} from 'react'
import {auth} from '../firebase'
import { useHistory } from "react-router-dom"
// DATA 
import {Alert, CircularProgress} from "@mui/material"

// queries
import {
  updateSite,
  updateSiteSocials,
  getSite,
} from '../queries/siteQueries'

// UI

// mui components

// custom components
import AdminPage from '../components/Pages/AdminPage'

export default function Profile(){
  const history = useHistory()
  // state
  const [ siteName1, setSiteName ] = useState('')
  const [ siteId, setSiteId ] = useState('')
  const [ baseUrl, setBaseUrl ] = useState('')
  const [ siteLogo, setSiteLogo ] = useState('')
  const [ adminEmail1, setAdminEmail ] = useState('')
  const [ description1, setDescription ] = useState('')
  const [ twitter1, setTwitter ] = useState('')
  const [ facebook1, setFacebook ] = useState('')
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ currentUser, setCurrentUser] = useState()
  const [ instagram1, setInstagram ] = useState('')
  const [ youtube1, setYoutube ] = useState('')
  const [ tiktok1, setTiktok ] = useState('')
  const [ discord1, setDiscord ] = useState('')
  const [ pageloading, setPageLoading ] = useState(false)
  const [ pageerror, setPageError ] = useState(false)

  const handleSiteName = event => setSiteName(event.target.value)
  const handleSiteLogo = event => setSiteLogo(event.target.value)
  const handleBaseUrl = event => setBaseUrl(event.target.value)
  const handleDescription = event => setDescription(event.target.value)
  const handleAdminEmail = event => setAdminEmail(event.target.value)
  const handleTwitter = event => setTwitter(event.target.value)
  const handleFacebook = event => setFacebook(event.target.value)

  const handleInstagram = event => setInstagram(event.target.value)
  const handleYoutube = event => setYoutube(event.target.value)

  const handleTiktok = event => setTiktok(event.target.value)
  const handleDiscord  = event => setDiscord (event.target.value)

  const handleClose = () => setSnack(false)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }
 
  // functions
  const updateSite1 = async () => {
    localStorage.setItem('site_name', siteName1)
    localStorage.setItem('site_logo', siteLogo)
    localStorage.setItem('base_url', baseUrl)

    const siteUpdate = {
      id: siteId,
      site_name: siteName1, 
      admin_email: adminEmail1,
      description: description1, 
      site_logo: siteLogo,
      base_url: baseUrl,
    }

    try {
      await updateSite(siteUpdate, currentUser.accessToken)
      showSnack('success', 'Updated site!')
    } catch(error){
      showSnack('error', 'Error updating site!')
    } finally {
      
    }
  }

  const updateSiteSocials1 = async () => {
    const siteUpdate = {
      id: siteId,
      instagram: instagram1, 
      discord: discord1,
      youtube: youtube1, 
      facebook: facebook1, 
      twitter: twitter1,
      tiktok: tiktok1,
    }

    try {
      await updateSiteSocials(siteUpdate, currentUser.accessToken)
      showSnack('success', 'Updated site!')
    } catch(error){
      showSnack('error', 'Error updating site!')
    } finally {
      
    }
  }

  useEffect(() => {
    async function getData(token){
      try {
        const data = await getSite(token)

        if(data){
          const site = data[0]
          localStorage.setItem('site_name', site.site_name)
          localStorage.setItem('site_logo', site.site_logo)
          setAdminEmail(site.admin_email)
          setSiteName(site.site_name)
          setDescription(site.description)
          setFacebook(site.facebook)
          setTwitter(site.twitter)
          setSiteId(site.id)
          setSiteLogo(site.site_logo)
          setBaseUrl(site.base_url)
          setInstagram(site.instagram)
          setDiscord(site.discord)
          setYoutube(site.youtube)
          setTiktok(site.tiktok)
        }
      } catch(error){
        setPageError(true)
      } finally{
        setPageLoading(false)
      }
    }

    if(currentUser){
      getData(currentUser.accessToken)
    } 
   
    const unsubscribe = auth.onAuthStateChanged(user => {
      if(user){
        setCurrentUser(user)
      } else {
        history.push('signin')
      }
    })
    return unsubscribe
  }, [currentUser])
  
  
  if(pageloading) return <CircularProgress />
  if(!pageloading && pageerror)  return <Alert severity="error" >Loading error!</Alert>

  return <AdminPage 
            msgType={msgType}
            alertMsg={alertMsg}
            snack={snack}
            loading={loading}
            siteName1={siteName1}
            adminEmail1={adminEmail1}
            description1={description1}
            twitter={twitter1}
            facebook={facebook1}
            handleClose={handleClose}
            handleSiteName={handleSiteName}
            handleAdminEmail={handleAdminEmail}
            handleDescription={handleDescription}
            handleFacebook={handleFacebook}
            handleTwitter={handleTwitter}
            updateSite={updateSite1}
            handleSiteLogo={handleSiteLogo}
            siteLogo={siteLogo}
            baseUrl={baseUrl}
            handleBaseUrl={handleBaseUrl}
            handleInstagram={handleInstagram}
            handleDiscord={handleDiscord}
            handleTiktok={handleTiktok}
            handleYoutube={handleYoutube}
            instagram={instagram1}
            discord={discord1}
            tiktok={tiktok1}
            youtube={youtube1}
            updateSiteSocials={updateSiteSocials1}
          />
}