import React from "react";

import Button from '@mui/material/Button'


export default function BasicIconButton({
    actionFunc, 
    title,
    color='primary',
    icon,
    size='medium',
    fullWidth,
}){

  return(
      <Button 
        fullWidth={fullWidth}
        sx={{
          color:'white',
          backgroundColor: '#6415ff',
          '&:hover': {
            backgroundColor: '#4719a3',
          },
        }}
        size={size}
        variant="contained" 
        onClick={actionFunc}
        startIcon={icon}
      >
        {title}
      </Button>
  )
}