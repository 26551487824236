import React, { Fragment } from "react"
import { Link as RouterLink } from 'react-router-dom'

import Button from '@mui/material/Button'

const AppBarButton = ({
    title,
    href,
    icon
}) =>   <Fragment>
            <Button 
                component={RouterLink}
                color="inherit" 
                endIcon={icon} 
                to={href} 
                sx={{mr:2}}
            >
                {title}
            </Button>
        </Fragment>

export default AppBarButton