import * as React from 'react';
import Link from '@mui/material/Link'
import moment from 'moment'

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

function FeaturedPost({  
  title, 
  published_date,
  post_image,
 }) {
  const date = moment(published_date).format('MMMM Do, YYYY')
  return (
      <Grid item xs={12} md={6}>
        <CardActionArea  sx={{ height:'100%' }}>
          <Card sx={{ display: 'flex', height:'100%' }}>
            <CardContent style={{ flex: 1 }}>
              <Typography variant="caption" >
                {date}
              </Typography>
              <Link href=''  >
                <Typography component="h6" variant="h6">
                  {title}
                </Typography>
              </Link>
            </CardContent>
            <CardMedia
              component="img"
              style={{ width: 160, display: { xs: 'none', sm: 'block' } }}
              image={post_image}
              alt={title}
            />
          </Card>
        </CardActionArea>
      </Grid>
  )
}

export default FeaturedPost;