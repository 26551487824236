import React from "react";

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'

const AuthorDropDown = ({
    authors,
    handlePostAuthor,
    postAuthor,
}) => 
    <FormControl  fullWidth size="small"  sx={{minWidth: 100}}>
        <InputLabel >
            Author *
        </InputLabel>
        <Select
            required={true}
            label='Author'
            value={postAuthor}
            onChange={handlePostAuthor}
            sx={{backgroundColor: 'white'}}
        >
        {authors.map(a => 
            <MenuItem key={a.id} value={a.id}>
                {a.name}
            </MenuItem>
        )}
        </Select>
    </FormControl>

export default AuthorDropDown