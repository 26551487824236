import React, {useState, useEffect} from "react"
import { useHistory } from "react-router-dom"
import {auth} from '../firebase'
import {Alert, CircularProgress} from "@mui/material"

// helpers
import { deployCF } from "../utils"

// DATA

import {
  getAllPosts
} from '../queries/blogQueries'

// UI

// custom components
import PostListPage from '../components/Pages/PostListPage'

export default function PostList(){

  // DATA
  const history = useHistory()
  // get

  const role = localStorage.getItem('role')

  const publisher = role==='admin' || role==='editor' ?  true : false

  // state
  const [ loading, setLoading ] = useState(false)
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ msgType, setMsgType ] = useState('info')
  const [ snack, setSnack ] = useState(false)
  const [ posts, setPosts ] = useState([])
  const [ currentUser, setCurrentUser ] = useState()
  const [ pageloading, setPageLoading ] = useState(false)
  const [ pageerror, setPageError ] = useState(false)

  const handleClose = () => setSnack(false)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  // functions
  const publishPosts = async () => {
    if(!publisher){
      showSnack('error', 'You are not authorized to publish!')
    }

    setLoading(true)

    try {
      await deployCF(currentUser.accessToken)
      showSnack('success', 'Posts published!')
    } catch(error){
      showSnack('error', 'Publising error!')
    }
  }

  useEffect(()=> {
      async function getData(token){
        try {
          const data = await getAllPosts(token)
  
          if(data && data.posts && data.posts.length>0){
            setPosts(data.posts)
          }
        } catch(error){
          setPageError(true)
        } finally{
          setPageLoading(false)
        }
    }

  
    if(currentUser){
        setPageLoading(true)
        getData(currentUser.accessToken)
    }

    const unsubscribe = auth.onAuthStateChanged(user => {
      if(user){
        setCurrentUser(user)
      } else {
        history.push('/signin')
      }
    })
    
    return unsubscribe
  },[currentUser])

  if(pageloading) return <CircularProgress />
  if(!pageloading && pageerror)  return <Alert severity="error" >Loading error!</Alert>
  
  return <PostListPage
          publishPosts={publishPosts}
          loading={loading}
          snack={snack}
          publisher={publisher}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
          posts={posts}
          authorName=' '
          previewUrl='/blog_preview' 
        />
}