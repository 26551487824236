import React from "react"
import FadeIn from 'react-fade-in'
import Grid from '@mui/material/Grid'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Typography } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton'

import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import PublishIcon from '@mui/icons-material/Publish'
import PreviewIcon from '@mui/icons-material/Preview'

import SnackAlert from '../Dialogs/SnackAlert'
import BasicLinkIconButton from '../Buttons/BasicLinkIconButton'
import PublishedPosts from '../Cards/PublishedPosts'
import UnPublishedPosts from '../Cards/UnPublishedPosts'


export default function PostListPage({ 
  snack,
  msgType,
  publishPosts,
  publisher,
  alertMsg,
  loading,
  handleClose,
  posts,
  authorName,
  tagColor,
  previewUrl,
 }){
    const publishedPosts = posts.filter(p => p.published===1)
    const pubCount = publishedPosts.length
    return(
  <FadeIn delay={100}>
      <main >
            <Grid container  
                  alignItems="center" 
                  spacing={1} 
                  justifyContent='space-between'
            >
               <Grid item > 
               <Grid container spacing={3}>
                  <Grid item>
                    <Typography 
                      variant="h5"  
                        color={tagColor ? tagColor : 'primary'}>
                      {authorName.length>0 && `${authorName} Posts `} 
                    </Typography>
                  </Grid>
                 
                  </Grid>
               </Grid>
               <Grid item > 
                <Grid container  
                  alignItems="center" 
                  spacing={2} 
                >
                   {publisher  && pubCount>0 &&
                    <Grid item > 
                      <LoadingButton
                        size='small'
                        loading={loading}
                        onClick={publishPosts} 
                        color='success'
                        variant="contained"
                        startIcon={<PublishIcon />}
                      >
                        Publish
                      </LoadingButton>
                    </Grid>
                    }
                  {pubCount>0 &&
                   <Grid item > 
                      <Button 
                        size='small'
                        color='warning'
                        variant="contained"
                        href={previewUrl} 
                        target='_blank'
                        startIcon={<PreviewIcon />}
                      >
                        Preview
                      </Button>
                   </Grid>
                    }
                  <Grid item > 
                    
                    <BasicLinkIconButton
                        size="small"
                        title="Add"
                        to='/user/add_post'
                        icon={<AddIcon />}
                    />
                  </Grid>
                  <Grid item > 
                    <Button 
                      size='small'
                      color='info'
                      variant="contained"
                      to='/user/search_posts'
                      component={RouterLink} 
                      startIcon={<SearchIcon />}
                    >
                        Search
                    </Button>
                  </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12}> 
                <Grid container  
                    alignItems="start" 
                    spacing={2} 
                    justifyContent='space-between'
                >
                  <Grid item xs={12} md={6}>
                    <UnPublishedPosts allPosts={posts}  />
                  </Grid>
                  <Grid item  xs={12} md={6}>
                    <PublishedPosts allPosts={posts}  />
                  </Grid>
                </Grid>
              </Grid>
            </Grid> 
            <SnackAlert 
              snack={snack}
              handleClose={handleClose}
              msgType={msgType}
              alertMsg={alertMsg}
            /> 
        </main>
        </FadeIn>
    )
}