import React from "react";

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'

const TagDropDown = ({
    tag,
    handleTag,
    tags,
}) => {

  return (    
  <FormControl  fullWidth size="small"  sx={{minWidth: 150}}>
        <InputLabel >
            Tag *
        </InputLabel>
        <Select
            required={true}
            label='Tag'
            value={tag}
            onChange={handleTag}
            sx={{backgroundColor: 'white'}}
        >
        {tags && tags.map((t, i) => 
            <MenuItem key={i} value={t.id}>
                {t.tag_name}
            </MenuItem>
        )}
        </Select>
    </FormControl>
  )
}

export default TagDropDown