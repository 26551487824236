import React, {useState, useEffect} from "react"
import { useHistory, useParams } from "react-router-dom"
import {auth} from '../firebase'
import {Alert, CircularProgress} from "@mui/material"

// DATA

import {
    getAuthorsPosts,
} from '../queries/blogQueries'

// UI

// custom components
import AuthorPage from '../components/Pages/AuthorPage'

export default function AuthorList(){

  // DATA
  const history = useHistory()

  const {
    author_id, 
  } = useParams()

  // get

  // state
  const [ posts, setPosts ] = useState([])
  const [ tags, setTags ] = useState([])
  const [ site, setSite ] = useState([])
  const [ currentUser, setCurrentUser ] = useState()
  const [ pageloading, setPageLoading ] = useState(false)
  const [ pageerror, setPageError ] = useState(false)

  // functions
  useEffect(()=> {
      async function getData(author_id, token){
      try {

        const data = await getAuthorsPosts({author_id}, token)
     
        if(data){
            setPosts(data.posts)
            setTags(data.tags)
            setSite(data.site)
        }

      } catch(error){
        setPageError(true)
      } finally{
        setPageLoading(false)
      }
    }

  
    if(currentUser){
        getData(author_id, currentUser.accessToken)
    }

    const unsubscribe = auth.onAuthStateChanged(user => {
      if(user){
        setCurrentUser(user)
      } else {
        history.push('/signin')
      }
    })
    
    return unsubscribe
  },[author_id, currentUser])

  if(pageloading) return <CircularProgress />
  if(!pageloading && pageerror)  return <Alert severity="error" >Loading error!</Alert>

  const total_count = posts.length

  return <AuthorPage
            posts={posts}
            site={site}
            tags={tags}
            page_num={1}
            auth_id={author_id}
            total_count={total_count}
        />
}