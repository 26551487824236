import React, { useState, useEffect, useCallback, useRef } from "react"
import FadeIn from 'react-fade-in';

import {auth} from '../firebase'
import {Alert, Button, CircularProgress} from "@mui/material"

// packages
import { useHistory } from "react-router-dom"

// DATA

// queries
import {
  createPost,
  getAddPostData,
} from '../queries/blogQueries'

import {
  createTag,
  deleteTag,
  updateTag,
} from '../queries/tagsQueries'

// UI

// custom components
import SnackAlert from '../components/Dialogs/SnackAlert'
import LoadingIconButton from '../components/Buttons/LoadingIconButton'
import BasicIconButton from '../components/Buttons/BasicIconButton'

import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../tools'

// mui
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import ImageIcon from '@mui/icons-material/Image'
import AddIcon from '@mui/icons-material/Add'
import Link from "@mui/material/Link"

import ListIcon from '@mui/icons-material/List';

// custom components
import TagDropDown from '../components/Inputs/TagDropDown'
import BlogInput from '../components/Inputs/BlogInput'
import TagsDialog from '../components/Dialogs/TagsDialog'
import EditTagDialog from '../components/Dialogs/EditTagDialog'

import AddTagDialog from '../components/Dialogs/AddTagDialog'
import PostTag from '../components/Inputs/PostTag'
import AuthorDropDown from '../components/Inputs/AuthorDropDown'
import { Divider, Typography, Card } from "@mui/material"
import Modal from '@mui/material/Modal';

import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

// custom components

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
};


const imageSites = [
  {
      title: 'Unsplash',
      url: 'https://unsplash.com'
  },
  {
      title: 'Pexels',
      url: 'https://www.pexels.com'
  },
  {
      title: 'Pixabay',
      url: 'https://pixabay.com/'
  },
]

export default function AddPost(){

    const ReactEditorJS = createReactEditorJS()

    let history = useHistory()

    // fetch

     // change

    const editorCore = useRef(null);
    // state
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)
    const [ title, setTitle ] = useState('')
    const [ summary, setSummary ] = useState('')
    const [ postAuthor, setPostAuthor ] = useState(0)
    const [ post_image, setPost_image ] = useState('')
    const [ tag, setTag ] = useState(0)
    const [ basicInfoOpen, setInfoOpen ] = useState(true)
    const [ postTags, setPostTags ] = useState([])
    const [ tagColor, setTagColor ] = useState('#000000')
    const [ tagName, setTagName ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ tagOpen, setTagOpen ] = useState(false)
    const [ tagsOpen, setTagsOpen ] = useState(false)
    const [ blocks, setBlocks ] = useState()
    const [ tags, setTags ] = useState([])
    const [ authors, setAuthors ] = useState([])
    const [ currentUser, setCurrentUser] = useState()
    const [ pageloading, setPageLoading ] = useState(false)
    const [ pageerror, setPageError ] = useState(false)
    const [imgOpen, setImgOpen] = useState(false);
    const [ editTagOpen, setEditTagOpen ] = useState(false)
    const [ editTagId, setEditTagId ] = useState(0)

    const toggleImgOpen = event => setImgOpen(!imgOpen)
    const handleTag = event => setTag(event.target.value)
    const handlePost_image = event => setPost_image(event.target.value)
    const handleSummary = event => setSummary(event.target.value)
    const handleTitle = event => setTitle(event.target.value)
    const handlePostAuthor = event => setPostAuthor(event.target.value)
    const handleTagName = event => setTagName(event.target.value)
    const toggleInfoOpen = event => setInfoOpen(!basicInfoOpen)
    const handleClose = () => setSnack(false)
    const toggleTagOpen = event => setTagOpen(!tagOpen)
    const toggleTagsOpen = event => setTagsOpen(!tagsOpen)
    const toggleEditTagOpen = event => setEditTagOpen(!editTagOpen)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }


    const chooseEditTag = tag_id => {
      setEditTagId(tag_id)
      toggleEditTagOpen()
      setTagsOpen(false)
      setTagOpen(false)
    }    

    // functions

    const addPost = async () => {
      const name = localStorage.getItem('name')
      if(!name || name.length===0){
         showSnack('error', 'Please visit your profile and add your name.')
         return
      }
      if(title.length>0 && summary.length>0 && post_image.length>0 && blocks!==null && blocks.blocks.length>1){
        
        setLoading(true)
        const update_author=localStorage.getItem('name')
        const token = currentUser.accessToken

        const newTags = postTags.map(t => ({tag_id: t}))
    
        const newPost = {
          title,
          summary,
          post_image,
          postTags: newTags,
          blocks,
          author_id: parseInt(postAuthor),
          update_author,
        }
       
        try {
          const data = await createPost(newPost, token)
          if(data){
            history.push(`/user/posts`)
          } else {
            showSnack('error', 'Error adding new post! ')
          }
       
        } catch(error){
          showSnack('error', 'Error adding new post! ')
        } finally {
          setLoading(false)
        }
    
      } else {
        showSnack('error', 'Please add text in the required fields')
      }
    }

    const updateTag1 = async (tag_id, tagName, tagColor) => {
      toggleEditTagOpen()

      const tagUpdate = {
        tagName, 
        tagColor,
        tag_id
      }

      try {
        await updateTag(tagUpdate, currentUser.accessToken)

        const newtags = tags.filter(p => p.id!==tag_id)

        const addTag = {
          tag_name: tagName, 
          tag_color: tagColor, 
          id: tag_id,
        }

        const tagList = [...newtags, addTag]

        const firstTag = tagList[0].id

        setTags(tagList)
        setTag(firstTag)
        setEditTagId(0)
  
        showSnack('success', 'Tag updated!')
      } catch(error){
        showSnack('error', 'Error adding tag!')
      }
    }


    const addPostTag = () => {
      if(!postTags.includes(tag)){
        setPostTags([...postTags, tag])
      }
    }

    const deletePostTag = (id) => {
      const newtags = postTags.filter(p => p!==id)
      setPostTags(newtags)
    }

    const handleSave = useCallback(async () => {
      const savedData = await editorCore.current.save();
      setBlocks(savedData);
    }, [setBlocks]);

    const handleInitialize = useCallback((instance) => {
      instance._editorJS.isReady
        .then(() => {
          editorCore.current = instance;
        })
     
    }, []);

    const deleteTag1 = async tag_id => {

      try {
        await deleteTag({
            tag_id
          },
         currentUser.accessToken)
         const newtags = tags.filter(p => p.id!==tag_id)

          setTags(newtags)
          setTag(newtags[0].id)
          const newposttags = postTags.filter(p => p!==tag_id)
          setPostTags(newposttags)
      } catch(error){
        showSnack('error', 'Error adding tag!')
      } finally {
        toggleTagsOpen()
      }
    }

    const newTag = async () => { 
      toggleTagOpen()
      const token = currentUser.accessToken

      try {
        const data = await createTag({
          tagName,
          tagColor,
        }, token)

        if(data.tag_id){

          const newt = {
            id: data.tag_id,
            tag_name: tagName,
            tag_color: tagColor,
          }
      
          const tagList = [...tags, newt]
          const firstTag = tagList[0].id
 
          setTags(tagList)
          setTagName('')
          setTag(firstTag)
          showSnack('success', data.message)
        } else {
          showSnack('error', 'Error adding tag!')
        }
      
      } catch(error){

        showSnack('error', 'Error adding tag!')
      } finally{
        setLoading(false)
      }
    }

    useEffect(() => {
     
      async function setData(token){
        const author_id = localStorage.getItem('author_id')

        try {
          const data = await getAddPostData(token)

          if(data!==null && data.activeAuthors.length>0){
            setAuthors(data.activeAuthors)
            setPostAuthor(author_id)
          }

          if(data && data.tags.length){
            setTag(data.tags[0].id)
            setTags(data.tags)
          }
        } catch(error){
          setPageError(true)
        } finally{
          setPageLoading(false)
        }
      }

      if(currentUser){
        setPageLoading(true)
        setData(currentUser.accessToken)
      }
     
      const unsubscribe = auth.onAuthStateChanged(user => {
        if(user){
          setCurrentUser(user)
        } else {
          history.push('login')
        }
      })

      return unsubscribe
    }, [currentUser])

    if(pageloading) return <CircularProgress />
    if(!pageloading && pageerror)  return <Alert severity="error" >Loading error!</Alert>
    
    return (
      <FadeIn delay={100}>
      <main >
        <Grid container  
              alignItems="center" 
              spacing={2} 
              justifyContent='space-between'
        >   
          <Grid item xs={12}> 
          {basicInfoOpen ?
            <Grid container  
                  alignItems="center" 
                  spacing={2} 
                  justifyContent='space-between'
            >
                <Grid item xs={12} md={9} lg={10}> 
                  <FormControl sx={{ width: '100%'}}>
                    <BlogInput 
                      required={true}
                      title={title}
                      handleTitle={handleTitle}
                      label="Title" 
                    />
                  </FormControl>  
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  {authors.length>0 && 
                    <AuthorDropDown
                      authors={authors}
                      postAuthor={postAuthor}
                      handlePostAuthor={handlePostAuthor}
                    />
                  }
                </Grid>
                <Grid item xs={12}> 
                  <BlogInput 
                    required={true}
                    title={summary}
                    handleTitle={handleSummary}
                    label="Summary" 
                  />
                </Grid>  
                <Grid item xs={12}> 
                  <Grid container  
                      alignItems="center" 
                      spacing={2} 
                      justifyContent='space-between'
                  >
                    <Grid item >
                      <Grid container  
                        alignItems="center" 
                        spacing={2} 
                        justifyContent='space-between'
                    >
                      {tags && tags.length>0 &&
                        <Grid item  >
                          <TagDropDown 
                            tag={tag}
                            tags={tags}
                            handleTag={handleTag}
                          />
                        </Grid>
                      }
                      <Grid item> 
                        <BasicIconButton 
                          size='small'
                          title='Post Tag'
                          actionFunc={addPostTag}
                          icon={<AddIcon />}  
                          />
                      </Grid>
                      {postTags.length>0 && 
                        <Grid item > 
                          <Grid container spacing={2} alignItems='center'>
                            {tags.length>0 && postTags.map(p =>  
                              <PostTag 
                                key={p} 
                                tags={tags} 
                                tag_id={p} 
                                deletePostTag={deletePostTag} 
                              />
                            )}
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                </Grid> 
                <Grid item xs={12}> 
                  <Grid container  
                      alignItems="center" 
                      spacing={2} 
                      justifyContent='space-between'
                  >
                    <Grid item >
                      <Grid container  
                        alignItems="center" 
                        spacing={2} 
                        justifyContent='space-between'
                    >
                       <Grid item> 
                        <BasicIconButton 
                          size='small'
                          title='Tags'
                          actionFunc={toggleTagsOpen}
                          icon={<ListIcon />}  
                          />
                      </Grid>
                      <Grid item> 
                        <BasicIconButton 
                          size='small'
                          title='Add Tag'
                          actionFunc={toggleTagOpen}
                          icon={<AddIcon />}  
                          />
                      </Grid>
                    
                    
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> 
              <Grid item  xs={12}> 
                  <Grid container justifyContent='space-between' >
                    <Grid item md={8} xs={12} > 
                      <BlogInput 
                        required={true}
                        title={post_image} 
                        handleTitle={handlePost_image}
                        label="Post Image" 
                      />
                    </Grid>

                    <Grid item >
                      {post_image.length>0 ?
                        <img onClick={toggleImgOpen}  src={post_image} alt='selected pick' height='60' style={{borderRadius: 5, cursor: 'pointer'}}/>
                        :
                        <ImageIcon sx={{ fontSize: 36 }}  color='primary'/>
                      }
                    </Grid> 

                    <Grid item xs={12}>
                      <Grid container spacing={1}  >
                        {imageSites.map(s => 
                          <Grid item key={s.title}>
                            <Link href={s.url} target="_blank" color='#6415ff' variant='body2'  >
                              {s.title}
                            </Link>
                          </Grid>
                        )}
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
              
            </Grid>
            :
            <Grid item  >
                <Typography variant="h6">
                  {title}
                </Typography>
              </Grid>
            }
              <Grid item  xs={12}> 
                  <Divider sx={{mt:2, mb: 1}} />
                </Grid>
             <Grid item xs={12}> 
              <Grid container justifyContent='space-between'>
                <Grid item  >
                  <LoadingIconButton
                    size="small"
                    onClick={addPost}
                    title='Add Article'
                    loading={loading}
                    icon={<AddIcon />}
                  /> 
                </Grid>

                <Grid item  >
                  <Button
                   size="small"
                    variant="contained"
                    onClick={toggleInfoOpen}
                    startIcon={basicInfoOpen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                  >
                    {basicInfoOpen ? 'Collapse' : 'Expand'}
                  </Button>
                </Grid>

              </Grid>

             </Grid>
             <Grid item  xs={12}> 
              <Divider sx={{mt:1, mb: 2}} />
            </Grid>
            <Grid item xs={12}> 
              <ReactEditorJS 
                defaultValue={blocks} 
                tools={EDITOR_JS_TOOLS} 
                onChange={handleSave}
                onInitialize={handleInitialize}
              />
            </Grid>
          </Grid>
        </Grid>
        <AddTagDialog  
          handleTagColor={setTagColor}
          tagColor={tagColor}
          handleTagName={handleTagName}
          tagName={tagName}
          errorText=''
          newTag={newTag}
          tagOpen={tagOpen}
          toggleTagOpen={toggleTagOpen}
          deleteTag={deleteTag1}
          tags={tags}
        />
         <EditTagDialog  
          tagOpen={editTagOpen}
          toggleTagOpen={toggleEditTagOpen}
          editTag={updateTag1}
          tags={tags}
          tagId={editTagId}
        />
         <TagsDialog 
          tagOpen={tagsOpen}
          toggleTagOpen={toggleTagsOpen}
          deleteTag={deleteTag1}
          chooseEditTag={chooseEditTag}
          tags={tags}
        />
        <SnackAlert 
          snack={snack}
          handleClose={handleClose}
          msgType={msgType}
          alertMsg={alertMsg}
        /> 
         <Modal
            open={imgOpen}
            onClose={toggleImgOpen}
          >
              <Card sx={style}>
                <img src={post_image} alt={title} style={{width: '100%'}}  />
              </Card>
             
 
          </Modal>
      </main>
      </FadeIn>
    )
    
   
}