import {postCF} from '../utils'

export const createPost =  async (newPost, token) => {
    try {
        return await postCF(`/create_post`, token, newPost)
    } catch(error){
        throw error
    }
}

export const updatePost = async (update, token) => {
    try {
        return await postCF(`/update_post`, token, update)
    } catch(error){
        throw error
    }
}

export const deletePost = async ({id}, token) => {
    try {
        return await postCF(`/delete_post`, token, {id})
    } catch(error){
        throw error
    }
}

export const updateSocials = async (update, token) => {
    // { twitter, facebook, instagram, tiktok, discord, youtube, id }
    try {
        return await postCF(`/update_post_socials`, token, update)
    } catch(error){
        throw error
    }
}

export const updatePostSocials = async (update, token) => {

    // { twitter, facebook, instagram, tiktok, youtube, id }

    try {
        return await postCF(`/update_post_socials`, token, update)
    } catch(error){
        throw error
    }
}


export const searchPostsTitle = async ({query}, token) => {
    try {
        return await postCF(`/search_title`, token, {query})
    } catch(error){
        throw error
    }
  }

export const searchPostsBody = async ({queryText}, token) => {
    try {
        return await postCF(`/search_posts`, token, {queryText})
    } catch(error){
        throw error
    }
  }

export const getTagsPosts = async ({tag_id}, token) => {
    try {
        return await postCF('/all_posts_tag_page', token, {
            tag_id,
            page: 0,
        })
        
    } catch(error){
        throw error
    }
}


export const getAuthorsPosts = async ({author_id}, token) => {
    try {
        return await postCF('/all_posts_author_page', token, {
            author_id,
            page: 0,
          })
        
        
    } catch(error){
        throw error
    }
}

export const publishPost = async (update, token) => {
    try {
        return await postCF(`/publish_post`, token, update)
    } catch(error){
        throw error
    }
}

export const getPost = async ({id}, token) => {
    try {
        return await postCF(`/post`, token, {id})
    } catch(error){
        throw error
    }
  }

export const getAllPosts = async (token) => {
    try {
        return await postCF(`/all_posts`, token)
        } catch(error){
            throw error
    }
}

export const getAddPostData = async (token) => {
    try {
        return await postCF(`/add_post_data`, token)
    } catch(error){
        throw error
    }
  }

  export const getUpdatePostData = async ({id}, token) => {
    try {
        return await postCF(`/update_post_data`, token, {id})
    } catch(error){
        throw error
    }
  }

export const getPublishedPosts = async (token) => {
    try {
        return await postCF(`/published_posts`, token)
    } catch(error){
        throw error
    }
}

export const getRecentPosts = async (token) => {
    try {
        return await postCF(`/recent_posts`, token)
    } catch(error){
        throw error
    }
}

export const getPostPreview = async (id, token) => {
    try {
        return await postCF('/published_post_preview', token, {id})
      } catch(error){
        throw error
      }
}


export const getFrontPagePreview = async (token) => {
    try {
        return await postCF('/front_page_posts', token, {})
      } catch(error){
        throw error
      }
}

export const getPostsAuthor = async (author_id, token) => {
    try {
        return await postCF(`/all_author_posts`, token, {author_id})
    } catch(error){
        throw error
    }
}

export const getPostsTag = async (tag_id, token) => {
    try {
        return await postCF(`/all_tag_posts`, token, {tag_id})
    } catch(error){
        throw error
    }
}

// export const uploadImage = async ({
//     filePath, 
//     file,
// }, token) => {
//     try {
//         return await postCF(`/upload_image`, token, {filePath, file})
//     } catch(error){
//         throw error
//     }
// }

export const getTags = async (token) => {
    try {
        return await postCF(`/tags`, token)
    } catch(error){
        throw error
    }
}

export const createTag =  async ({
    tagName,
    tagColor,
}, token) => {
    try {
        const data =  await postCF(`/create_tag`, token, {
            tag_name: tagName,
            tag_color: tagColor,
        })
        return data
    } catch(error){
        throw error
    }
}
 
// 