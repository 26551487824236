import React from 'react'
import { Link as RouterLink } from 'react-router-dom'


import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'

export default function LoginLink(){
    const haveaccount = 'Already have an account? Sign in'
    return(
        <Grid container justifyContent="flex-end">
            <Grid item>
                <Link 
                    to="/signin"
                    component={RouterLink} 
                    variant="body2"  
                    color="#6415ff"
                >
                    {haveaccount}
                </Link>
            </Grid>
        </Grid>
    )
}