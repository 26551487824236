import React from 'react'
import { langSwitch } from '../../utils'

import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Avatar from '@mui/material/Avatar';


const TagHeader = ({
    tag_name,
    tag_color,
    tag_id
}) => {
    return  <Grid item key={tag_name}>
                <Link  href={`/tag/${tag_id}/page/1`} sx={{color: tag_color, fontSize: 16}} variant='subtitle1'>
                    {tag_name}
                </Link>
            </Grid>
}

export const TagLangHeader = ({
    tag_name,
    id,
}) => {
    return  <Grid item>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        <Avatar sx={{ width: 18, height: 18 }} alt={tag_name} src={`/${tag_name}.svg`} />
                    </Grid>
                    <Grid item>
                        <Link  href={`/tag/${id}/page/1`} sx={{color: '#6415ff', fontSize: 14}} variant='subtitle1'>
                            {langSwitch(tag_name)}
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
}


const TagsHeader = ({
    tags
}) => 
    <Grid container 
          spacing={2}>
        {tags && tags.length>0 && tags.map((t, i) => 
            <TagHeader key={i} {...t} />
        )}
    </Grid>

export const LangTagsHeader = ({
    langTags
}) => 
    <Grid container 
          justifyContent='space-around' 
          spacing={2}>
        {langTags && langTags.length>0 && langTags.map(t => 
            <TagLangHeader  key={t.tag_name} {...t} />
        )}
    </Grid>

export default TagsHeader