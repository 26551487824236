import React, { useState, useEffect } from "react"
import FadeIn from 'react-fade-in';
import {auth} from '../firebase'
import { useHistory } from "react-router-dom"

// DATA

// queries

import {
  getSitePrivacy, 
  updateSitePrivacy,
} from '../queries/siteQueries'

// UI

// mui
import { Alert} from "@mui/material"
// custom components

import LegalPage from "../components/Pages/LegalPage";

import SnackAlert from '../components/Dialogs/SnackAlert'

export default function UpdatePrivacy(){

    const history = useHistory()

    // DATA
    
    // fetching

    // state management
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)

    const [ pageloading, setPageLoading ] = useState(true)
    const [ pageerror, setPageError ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ blocks, setBlocks ] = useState()
    const [ siteId, setSiteId ] = useState(0)
    const [ updateDate, setUpdateDate ] = useState()
    const [ currentUser, setCurrentUser] = useState()


    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    // functions
    
    const updatePost1 = async (blocks) => {
    
      if(blocks!==null && blocks.blocks.length>1){
        setLoading(true)

        const updateDate = Date.now()

        const update = {
          blocks,
          updateDate,
          id: siteId,
        }
  
   
        try {
          await updateSitePrivacy(update, currentUser.accessToken)
          setUpdateDate(updateDate)
          showSnack('success', 'Terms updated!')
        } catch(error){
        
          showSnack('error', 'Error updating terms!')
        } finally {
          setLoading(false)
        }
      } else {
        showSnack('error', 'Please add text in the required fields')
      }
    }


    useEffect(()=>{
     
      // set fetched data
        async function setData(token){

          try {

            const data = await getSitePrivacy(token)
      
            setPageLoading(false)
            
            if(data && data.length>0){
             
              setSiteId(data[0].id)
              if(data[0].privacy_update_date){
                setUpdateDate(data[0].privacy_update_date)
              }
             
              if(data[0].privacy){

                const privacy = data[0].privacy
               
                const blk = JSON.parse(privacy)
     
                setBlocks(blk)
              }
            
            }

        } catch(error){
          setPageError(true)
        } finally{
          setPageLoading(false)
        }
    
      }

      if(currentUser){
        setPageLoading(true)
        setData(currentUser.accessToken)
      } 
     
      const unsubscribe = auth.onAuthStateChanged(user => {
        if(user){
          setCurrentUser(user)
        } else {
          history.push('signin')
        }
      })
      return unsubscribe
    },[currentUser])

    if(pageloading) return <div />
    if(!pageloading && pageerror)  return <Alert severity="error" >Loading error!</Alert>
     
    return (
      <FadeIn>
        <LegalPage 
             blocks={blocks}
             updateDate={updateDate}
             updateDoc={updatePost1}
             title='Privacy Policy'
             loading={loading}
        />
        <SnackAlert 
          snack={snack}
          handleClose={handleClose}
          msgType={msgType}
          alertMsg={alertMsg}
        /> 
 
      </FadeIn>
    )
}