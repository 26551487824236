import React from 'react'
// import moment from 'moment'
import { Link as RouterLink } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'

export default function SearchListItem({
    id,
    title,
}){
    return  <Grid item xs={12}>
                <Link  
                    variant='h6'
                    component={RouterLink} 
                    to={`/user/update_post/${id}`}  
                    style={{color: '#6415ff'}}
                >
                    {title}
                </Link>
            </Grid>
        
          
}