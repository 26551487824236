import React from "react"
import { Route, Switch } from "react-router-dom"

// mui
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

// pages
import AddPost from "./AddPost"
import UpdatePost from "./UpdatePost"
import PostList from "../pages/PostList"
import Profile from "../pages/Profile"
import Search from "../pages/Search"
import AuthorPostList from "../pages/AuthorPostList"
import TagPostList from "../pages/TagPostList"
import Admin from "../pages/Admin"
import UpdatePrivacy from "./UpdatePrivacy"
import UpdateTerms from "./UpdateTerms"

// import AddNewsletter from "./AddNewsletter"

// components
import AppBar1 from "../components/Nav/ResponsiveAppBar"


//protected routes pages
const routes = [ 
  { path: "/user/add_post",  component: AddPost, exact: true },
  { path: "/user/update_post/:id",  component: UpdatePost, exact: false },
  { path: "/user/author_posts/:author_id",  component: AuthorPostList, exact: false },
  { path: "/user/tag_posts/:tag_id",  component: TagPostList, exact: false },
  { path: "/user/posts",  component: PostList, exact: true },
  { path: "/user/profile",  component: Profile, exact: true },
  { path: "/user/search_posts",  component: Search, exact: true },
  { path: "/user/admin",  component: Admin, exact: true },
  // { path: "/user/add_newsletter",  component: AddNewsletter, exact: true },

  { path: "/user/terms",  component: UpdateTerms, exact: true },
  { path: "/user/privacy",  component: UpdatePrivacy, exact: true },
]

export default function User(){
  return <Box sx={{ flexGrow: 1 }}>
            <AppBar1 />
            <Box sx={{height: 10}}></Box>
            <Container component="main" maxWidth="xl" >
              <Switch key="router">
                {routes.map(route => (
                  <Route
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                  />
                ))}
              </Switch>
            </Container>
          </Box>
         
}
