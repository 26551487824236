import React from 'react'

// packages
import moment from 'moment'

// mui
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'

// custom components
import BlogHeader from './BlogHeader'
import PostContent  from './PostContent'

export default function PostPage({
  post,
  site,
  post_tags,
  recentPosts,
  posttags,
}) {
    
  const date = post.published_timestamp ? moment(post.published_timestamp).format('MMMM Do YYYY, h:mm A')  : ''
  const title = post.title ? post.title : ''
  const authorName = post.author_name ? post.author_name  : ''
  const authorId = post ? post.author_id  : -1
  const summary = post.summary ? post.summary : ''
  const article_text = post.article_text ? post.article_text.toString() : ''
  const blocks = post.blocks ? post.blocks : ''
  const tag = post.tags ? post.tags.tag_name : ''
  const tagId = post.tags ? post.tags.id : ''
  const post_image = post.post_image ? post.post_image : ''
  const siteName = site ? site.site_name : ''
  const siteLogo = site ? site.site_logo : ''
  const siteDescription = site ? site.description : ''
  const siteTwitter = site ? site.twitter : ''
  const siteFacebook = site ? site.facebook : ''
  const siteinstagram = site ? site.instagram : ''
  const sitediscord = site ? site.discord : ''
  const siteyoutube = site ? site.youtube : ''

  const adminEmail = site ? site.admin_email : ''
  const tagColor = post.tags ? post.tags.tag_color : ''
  const lang = ''

      return  <Container maxWidth="lg">
                 <BlogHeader 
                  siteName={siteName} 
                  siteLogo={siteLogo}
                  tags={posttags}
                />
                <Divider sx={{mt: 1, mb: 2}} /> 
                <PostContent 
                    date={date}
                    title={title}
                    authorName={authorName}
                    summary={summary}
                    article_text={article_text}
                    tag={tag}
                    lang={lang}
                    authorId={authorId}
                    tagColor={tagColor}
                    post_image={post_image}
                    siteDescription={siteDescription}
                    siteTwitter={siteTwitter}
                    siteinstagram={siteinstagram}
                    sitediscord={sitediscord}
                    siteyoutube={siteyoutube}
                    siteFacebook={siteFacebook}
                    adminEmail={adminEmail}
                    recentPosts={recentPosts}
                    tagId={tagId}
                    post_tags={post_tags}
                    taglang={''}
                    blocks={blocks}
                />
              </Container>
}