import  React, { useState} from 'react'

// packages
import { useHistory } from "react-router-dom"

// helpers
import { deployCF } from "../../utils"

// DATA

// client
import { getAuth, signOut } from "firebase/auth";
import {app} from '../../firebase'

// UI
// mui
import ListAltIcon from '@mui/icons-material/ListAlt'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
// custom components
import ResponsiveAppBarComponents from './ResponsiveAppBarComponent1'

const siteTitle =  'Langa Learn'

const buttons = [
    {
        title: 'Posts',
        href:  '/user/posts',
        icon: <ListAltIcon />
    },
    {
        title: 'Add Post',
        href:  '/user/add_post',
        icon: <AddIcon />
    },
    {
        title: 'Search',
        href:  '/user/search_posts',
        icon: <SearchIcon />
    },
]

const profileButtons = [
    {   
        title:  'Profile',
        link: '/user/profile',
    },
    {
        title: 'Admin', 
        link:"/user/admin", 
    },
]

const ResponsiveAppBar = () => {

    let history = useHistory(app)

    // data

    const email = localStorage.getItem('email')
    const role = localStorage.getItem('role')

    const publisher = role==='admin' || role==='editor' ?  true : false

    // state
    const [ anchorElNav, setAnchorElNav] = useState(null)
    const [ anchorProfileElNav, setAnchorProfileElNav] = useState(null)
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)

    const handleOpenProfileMenu = (event) => setAnchorProfileElNav(event.currentTarget)
    const handleCloseProfileMenu = () => setAnchorProfileElNav(null)
    const handleClose = () => setSnack(false)
    const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget)
    const handleCloseNavMenu = () => setAnchorElNav(null)

    const showSnack = (type, message) => {
        setSnack(true)
        setMsgType(type)
        setAlertMsg(message)
    }

    // functions
    const publishPosts = async () => {
      try {
        await deployCF()
      } catch(error){
        showSnack('error', 'Publising error!')
      }
    }

    const logout = async () => {
        const auth = getAuth();
        signOut(auth).then(() => {
                history.push({pathname: `/`})
            }).catch((error) => {
                showSnack('error', 'Logout error!')
            });
    }

    return <ResponsiveAppBarComponents
                buttons={buttons}
                profileButtons={profileButtons}
                siteTitle={siteTitle}
                publisher={publisher}
                anchorElNav={anchorElNav}
                anchorProfileElNav={anchorProfileElNav}
                alertMsg={alertMsg}
                msgType={msgType}
                snack={snack}
                handleClose={handleClose}
                handleOpenNavMenu={handleOpenNavMenu}
                handleCloseNavMenu={handleCloseNavMenu}
                publishPosts={publishPosts}
                logout={logout}
                role={role}
                email={email}
                section2={buttons}
                handleOpenProfileMenu={handleOpenProfileMenu}
                handleCloseProfileMenu={handleCloseProfileMenu}
            />
}

export default ResponsiveAppBar
