import React, {useState} from "react"
// import { Alert, CircularProgress } from "@mui/material"

// DATA

// queries
// import {
//   getUnpublishedPosts,
// } from '../../queries/blogQueries'

// UI

// custom components
import EditPostList  from '../Post/EditPostList'

export default function UnPublishedPost({
  allPosts
}){

  // DATA

  const [page, setPage] = useState(0)

  const changePage = (num) => setPage(prevState => prevState +num)

  const forPage = () => changePage(+1)
  const backPage = () => page>0 && changePage(-1)

  const start = page*10
  const end = start+9

  const unPublished = allPosts && allPosts.length > 0 ? allPosts.filter(p => p.published===0) : []
  const currPage = unPublished.slice(start, end)

  return    <EditPostList 
              posts={currPage} 
              title='Unpublished'
              forPage={forPage}
              backPage={backPage}
              page={page}
              postcount={unPublished.length}
            />
}