import  React, {useState, useEffect} from 'react'
import {auth} from '../firebase'
// packages
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"

// DATA
// queries
import {
  getPostPreview,
} from '../queries/blogQueries'

// UI
import {Alert, CircularProgress} from "@mui/material"

// custom componnets
import PostPage from '../components/Blog/PostPage'

export default function Post(){

    // DATA

    // fetch
    const {
      id, 
    } = useParams()

    let history = useHistory()

    const [ tags, setTags ] = useState([])
    const [ site, setSite ] = useState([])
    const [ post, setPost ] = useState([])
    const [ posttags, setposttags ] = useState([])
    const [ pageloading, setPageLoading ] = useState(true)
    const [ pageerror, setPageError ] = useState(false)
    const [ currentUser, setCurrentUser] = useState()
    const recentPosts = []

    useEffect(() => {
     
      async function setData(id){

       try {
        const data = await getPostPreview(id, currentUser.accessToken)
        if(data){
          setPost(data.post)
          setposttags(data.posttags)
          setTags(data.postTags)
          setSite(data.site)
        }

       } catch(error){
        setPageLoading(false)
        setPageError(error)
       } finally {
        setPageLoading(false)
       }
      }

      if(currentUser){
        setData(id)
      } 

      const unsubscribe = auth.onAuthStateChanged(user => {
        if(user){
          setCurrentUser(user)
        } else {
          history.push('signin')
        } 
      })
      return unsubscribe
    }, [id, currentUser])

    if(pageloading) return <CircularProgress />
    if(!pageloading && pageerror)  return <Alert severity="error" >Loading error!</Alert>

    return post ? <PostPage 
                    post={post}
                    site={site}
                    post_tags={tags} 
                    posttags={posttags}
                    recentPosts={recentPosts}
                    isLoading={false} 
                    isError={false} 
                    error={''}  
                  /> : <div />
}