import React from "react";
import FadeIn from 'react-fade-in'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from "@mui/material/Typography"
import SearchIcon from '@mui/icons-material/Search'
import red from '@mui/material/colors/red'

import SnackAlert from '../Dialogs/SnackAlert'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import SearchListItem from '../ListItems/SearchListItem'
import BlogInput from '../Inputs/BlogInput'

import { Divider } from "@mui/material"

const NoResults = () => <Typography color={red['A700']}>No results</Typography>

export default function SearchPage({ 
    text,
    loading,
    handleText,
    searchPosts,
    errorText,
    snack,
    msgType,
    alertMsg,
    handleClose,
    searchResults,
 }){
    return(
      <FadeIn delay={100}>
      <main >
            <Grid container  
                  alignItems="center" 
                  spacing={2} 
                  justifyContent='space-between'
            >
              <Grid item xs={12} md={10}> 
                <BlogInput 
                  title={text}
                  handleTitle={handleText}
                  errorText={errorText}
                  label='Search'
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <LoadingIconButton
                  size='small'
                  onClick={searchPosts}
                  title='Search'
                  loading={loading}
                  icon={<SearchIcon />}
                /> 
              </Grid>
            </Grid>
            <Card sx={{p:3, mt: 1}}>
              <Typography variant="h5" sx={{color:'#6415ff'}}>
                Search Results
              </Typography>
              <Divider sx={{mt:1, mb:2}} />
              {searchResults.length>0 ? 
                <Grid container spacing={2}>
                  {searchResults.map(r => 
                    <SearchListItem {...r} key={r.id} />
                  )}
                </Grid>
                :
                  <NoResults />
              }   
            </Card>
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          /> 
        </main>
        </FadeIn>
    )
}