import React, { useEffect, useState } from "react"
import {auth} from '../firebase'
import { useHistory } from "react-router-dom"

import {
  searchPostsBody,
} from '../queries/blogQueries'

// UI

// custom components
import SearchPage from '../components/Pages/SearchPage'

export default function AddPost(){
  const history = useHistory()
    // DAT

    // state
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)
    const [ url, setSearchLink ] = useState('')
    const [ featured, setFeatured ] = useState(false)
    const [ text, setText ] = useState('')
    const [ searchResults, setSearchResults ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ currentUser, setCurrentUser] = useState()

    const handleLink = event => setSearchLink(event.target.value)
    const handleText = event => setText(event.target.value)
    const handleClose = () => setSnack(false)
    const handleFeatured = () => setFeatured(!featured)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    // functions
    const searchPosts = async () => {
      setLoading(true)

      setLoading(true)

      try {
        const data = await searchPostsBody({queryText: text}, currentUser.accessToken)
        setSearchResults(data)
      } catch(error){
        showSnack('error','Search error!')
      } finally{
        setLoading(false)
      }
    }

   useEffect(()=>{
    const unsubscribe = auth.onAuthStateChanged(user => {
      if(user){
        setCurrentUser(user)
      } else {
        history.push('signin')
      }
    })
    return unsubscribe
   },[auth])

   return <SearchPage
            url={url}
            text={text}
            loading={loading}
            handleLink={handleLink}
            handleText={handleText}
            searchPosts={searchPosts}
            snack={snack}
            msgType={msgType}
            alertMsg={alertMsg}
            handleClose={handleClose}
            authorName=''
            searchResults={searchResults}
            handleFeatured={handleFeatured}
          />
}