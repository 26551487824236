import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"

// packages

// DATA

// clients
import {app} from '../firebase'

import { 
  getAuth, 
 } from "firebase/auth";

// queries

import {
  getSite,
  createSite,
} from '../queries/siteQueries'

// UI

// custom components
import CreateSitePage from '../components/Pages/CreateSitePage'

export default function CreateSite(){

  const history = useHistory()
  const auth = getAuth(app);

  // state
  const [ adminName, setAdminName ] = useState('')
  const [ siteName, setSiteName ] = useState('')
  const [ baseUrl, setBaseUrl ] = useState('')
  const [ siteLogo, setSiteLogo ] = useState('')
  const [ description, setDescription ] = useState('')
  const [ currentUser, setCurrentUser] = useState()
  const [ errorMsg, setErrorMsg] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)

  const handleAdminName = event => setAdminName(event.target.value)
  const handleSiteName = event => setSiteName(event.target.value)
  const handleSiteLogo = event => setSiteLogo(event.target.value)
  const handleBaseUrl = event => setBaseUrl(event.target.value)
  const handleDescription = event => setDescription(event.target.value)
  const handleClose = () => setSnack(false)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const inputs = [
    {
        name:'Admin Name',
        label:'Admin Name',
        handleFunc: handleAdminName,
        value: adminName
    },
    {
        name:'Site Name',
        label:'Site Name',
        handleFunc: handleSiteName,
        value: siteName
    },
    {
        name:'Site Logo',
        label:'Site Logo',
        handleFunc: handleSiteLogo,
        value: siteLogo
    },
    {
        name:'Base Url',
        label:'Base Url',
        handleFunc: handleBaseUrl,
        value: baseUrl
    },
    {
        name:'Description',
        label:'Description',
        handleFunc: handleDescription,
        value: description
    }
  ]


  // functions
  const createSite1 = async () => {

    setLoading(true)
    
    try {
      
        const sites = await getSite(currentUser.accessToken)
        if(sites.length>0){
          showSnack('The site has already been created!')
          setTimeout(() => {
            history.push('/signin')
          }, 300)
        
          return 
        }


        const newSite = {
          admin_email: currentUser.email,
          admin_name: adminName,
          site_name: siteName,
          site_logo: siteLogo,
          description,
          base_url: baseUrl,
        }
   
        await createSite(newSite, currentUser.accessToken)

        localStorage.setItem('site_name', siteName)
        localStorage.setItem('site_logo', siteLogo)
        localStorage.setItem('site_logo', siteLogo)
        localStorage.setItem('name', adminName)
        localStorage.setItem('role', 'admin')
        localStorage.setItem('email', currentUser.email)
        showSnack('success',  'Success!')

        setTimeout(() => {
          history.push('/user/posts')
        }, 300)
  
    } catch(error) {

      setErrorMsg(error.message)
      throw error
    } finally{
      setLoading(false)
    }
  }


  useEffect(() => {

    const unsubscribe = auth.onAuthStateChanged(user => {
      if(user){
        setCurrentUser(user)
      } else {
        history.push('signin')
      }
    })
    return unsubscribe
}, [auth])

  return <CreateSitePage
          inputs={inputs} 
          createSite={createSite1}
          errorMsg={errorMsg} 
          loading={loading}
          snack={snack}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
        />
}