import React, {useState} from "react"

// DATA

// queries

// UI

// custom components
import EditPostList  from '../Post/EditPostList'

export default function PublishedPosts({
    allPosts
}){

    // DATA

    // get
    const [page, setPage] = useState(0)
   
    const changePage = (num) => setPage(prevState => prevState +num)

    const forPage = () => changePage(+1)
    const backPage = () => page>0 && changePage(-1)
  
    const start = page*10
    const end = start+10

    const published = allPosts && allPosts.length > 0 ? allPosts.filter(p => p.published===1) : []
    const currPage = published.slice(start, end)

    return    <EditPostList 
                    posts={currPage} 
                    title='Published'
                    forPage={forPage}
                    backPage={backPage}
                    page={page}
                    postcount={published.length}
                />
}