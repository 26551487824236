import React, {useEffect, useState} from 'react'
import { auth } from "../firebase";
import { updateEmail } from "firebase/auth";
import { useHistory } from "react-router-dom"

// DATA 

// queries
import {
  updateMember,
} from '../queries/memberQueries'

// UI

// mui components

// custom components
import ProfilePage from '../components/Pages/ProfilePage'

export default function Admin(){
  const history = useHistory()
  // DATA

  // state
  const [ name1, setName ] = useState('')
  const [ email1, setEmail ] = useState('')
  const [ role, setRole ] = useState(false)

  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ currentUser, setCurrentUser] = useState()

  const handleName = event => setName(event.target.value)
  const handleEmail = event => setEmail(event.target.value)
  const handleClose = () => setSnack(false)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  // functions
  const updateProfile = async () => {
    const email = localStorage.getItem('email')
    const role = localStorage.getItem('role')
    const token = currentUser.accessToken
    const uid = currentUser.uid
     try {
        await updateMember({
          name: name1, 
          email: email1,
          role, 
          uid
        }, token)
        localStorage.setItem('name', name1)
        if (email !== email1){
         
          updateEmail(auth.currentUser, email1).then(() => {
            showSnack('success', 'Profile updated!')
          }).catch((error) => {
            showSnack('error', 'Error updating!')
          });
        }
        localStorage.setItem('email', email1)
        showSnack('success', 'Profile updated!')
     } catch(error) {
        showSnack('error', 'Error updating!')
     } finally {
        setLoading(false)
     }

  }

  useEffect(() => {
      const email = localStorage.getItem('email')
      const role = localStorage.getItem('role')
      const name = localStorage.getItem('name')
   
      setEmail(email)
      setName(name)
      setRole(role)

      const unsubscribe = auth.onAuthStateChanged(user => {
        if(user){
          setCurrentUser(user)
        } else {
          history.push('signin')
        }
      })
      return unsubscribe
  }, [auth])

  return <ProfilePage 
            name1={name1}
            email1={email1}
            msgType={msgType}
            alertMsg={alertMsg}
            snack={snack}
            loading={loading}
            handleClose={handleClose}
            handleName={handleName}
            handleEmail={ handleEmail}
            updateProfile={updateProfile}
            role={role}
          />
}