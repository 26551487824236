import React from 'react'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'

import EmailIcon from '@mui/icons-material/Email'

import LoginHeader  from '../Login/LoginHeader'
import Copyright from '../Login/Copyright'
import LoginInput from '../Inputs/LoginInput'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import SnackAlert from '../Dialogs/SnackAlert'

export default function CreateSitePage({
  inputs,
  createSite,
  snack,
  msgType,
  errorMsg,
  alertMsg,
  handleClose,
  loading,
}){

  const buttonItems = [
    {
      title: 'Create Site',
      icon: <EmailIcon />,
      onClick: createSite,
    },
  ]

  return (
    <Container component="main" maxWidth="xs">
      <Paper sx={{ padding: 2, marginTop: 2, backgroundColor: 'rgb(240, 246, 255)' }}> 
        <Grid container 
              justifyContent='center' 
              textAlign='center'
              spacing={2}
        >
          <Grid item xs={12}>
              <LoginHeader title='Sign Up' />
          </Grid>  
          {inputs.map(l => 
            <Grid item xs={12} key={l.name} >
              <LoginInput 
                {...l} 
                error={errorMsg} 
              />
            </Grid>
          )}
          {buttonItems.map(b => 
            <Grid item xs={12} key={b.title}>
              <LoadingIconButton 
                {...b}
                size='large'
                loading={loading}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>  
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Paper>
      <SnackAlert 
        snack={snack}
        handleClose={handleClose}
        msgType={msgType}
        alertMsg={alertMsg}
      />
    </Container>
  )
}