import React, {useState, useEffect} from "react"
import { useHistory, useParams } from "react-router-dom"
import {auth} from '../firebase'
import {Alert, CircularProgress} from "@mui/material"

// DATA

import {
    getTagsPosts,
} from '../queries/tagsQueries'

// UI

// custom components
import CategoryPage from '../components/Pages/CategoryPage'

export default function TagsList(){

  // DATA
  const history = useHistory()

  const {
    tag_id, 
  } = useParams()

  // get

  // state
  const [ posts, setPosts ] = useState([])
  const [ tags, setTags ] = useState([])
  const [ site, setSite ] = useState([])
  const [ currentUser, setCurrentUser ] = useState()
  const [ pageloading, setPageLoading ] = useState(true)
  const [ pageerror, setPageError ] = useState(false)

  // functions
  useEffect(()=> {
      async function getData(tag_id, token){
      try {

        const data = await getTagsPosts({tag_id}, token)

        if(data){
            setPosts(data.posts)
            setTags(data.tags)
            setSite(data.site)
        }

      } catch(error){
        setPageError(true)
      } finally{
        setPageLoading(false)
      }
    }

  
    if(currentUser){
        getData(tag_id, currentUser.accessToken)
    }

    const unsubscribe = auth.onAuthStateChanged(user => {
      if(user){
        setCurrentUser(user)
      } else {
        history.push('/signin')
      }
    })
    
    return unsubscribe
  },[tag_id, currentUser])

 
 

  if(pageloading) return <CircularProgress />
  if(!pageloading && pageerror)  return <Alert severity="error" >Loading error!</Alert>

  const curTag = tags.length>0 ? tags.filter(t => t.tag_id===parseInt(tag_id))[0] : null

  const total_count = posts.length
  return <CategoryPage
            site={site}
            tags={tags}
            curTag={curTag}
            page_num={1}
            tag_id={tag_id}
            total_count={total_count}
            posts={posts}
        />
}