import React, { useState, useEffect, useRef, useCallback } from "react"
import { createReactEditorJS } from 'react-editor-js'
import FadeIn from 'react-fade-in';
import { EDITOR_JS_TOOLS } from '../tools'
import moment from 'moment'
import {auth} from '../firebase'
import { useHistory } from "react-router-dom"
// packages
import { useParams } from "react-router-dom"

// helpers
import { slugify } from '../utils'

// DATA

// queries

import {
  updatePost,
  getUpdatePostData,
  publishPost,
  updatePostSocials,
  deletePost,
} from '../queries/blogQueries'

import {
  createTag,
  createPostTags,
  deletePostTag,
  deleteTag,
  updateTag,
} from '../queries/tagsQueries'

// UI

// mui
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Tooltip from '@mui/material/Tooltip'
import ImageIcon from '@mui/icons-material/Image'
import AddIcon from '@mui/icons-material/Add'
import Link from "@mui/material/Link"
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Card, CardMedia, IconButton, Divider, Typography, Button, Alert} from "@mui/material"
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ListIcon from '@mui/icons-material/List';
import LinkIcon from '@mui/icons-material/Link'
import DeleteIcon from '@mui/icons-material/Delete'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon  from '@mui/icons-material/Instagram'
import PreviewIcon from '@mui/icons-material/Preview'
import PublishIcon from '@mui/icons-material/Publish'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import EditIcon from '@mui/icons-material/Edit'
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import {TikTokIcon} from '../components/ImageElements/BlogIcons'

// custom components
import TagDropDown from '../components/Inputs/TagDropDown'
import BlogInput from '../components/Inputs/BlogInput'
import TagsDialog from '../components/Dialogs/TagsDialog'
import AddTagDialog from '../components/Dialogs/AddTagDialog'
import EditTagDialog from '../components/Dialogs/EditTagDialog'
import PostTag from '../components/Inputs/PostTag'
import AuthorDropDown from '../components/Inputs/AuthorDropDown'

import BasicIconButton from '../components/Buttons/BasicIconButton'

import LoadingIconButton from '../components/Buttons/LoadingIconButton'

import SnackAlert from '../components/Dialogs/SnackAlert'
import LoadingIconButton1 from '../components/Buttons/LoadingIconButton1'
import LoadingIconButton2 from '../components/Buttons/LoadingIconButton2'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
};


const imageSites = [
  {
      title: 'Unsplash',
      url: 'https://unsplash.com'
  },
  {
      title: 'Pexels',
      url: 'https://www.pexels.com'
  },
  {
      title: 'Pixabay',
      url: 'https://pixabay.com/'
  },
]

export default function UpdatePost(){

    const history = useHistory()
    const ReactEditorJS = createReactEditorJS()

    const editorCore = useRef(null);

    // DATA
    
    // fetching

    const { 
      id,
    } = useParams()


    // state management
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)
    const [ title, setTitle ] = useState('')
    const [ summary, setSummary ] = useState('')
    const [ featured, setFeatured ] = useState(0)
    const [ postAuthor, setPostAuthor ] = useState(2)
    const [ post_image, setPost_image ] = useState('')
    const [ tag, setTag ] = useState('s')
    const [ tagName, setTagName ] = useState('')
    const [ tagColor, setTagColor ] = useState('#000000')
    const [ published, setPublished ] = useState(false)
    const [ subscription, setSubscription ] = useState(0)

    const [ imgOpen, setImgOpen ] = useState(false);
    const [ deleteOpen, setDeleteOpen ] = useState(false)
    const [ publishDate, setPublishDate ] = useState('')
    const [ publishAuthor, setPublishAuthor ] = useState('')

    const [ pageloading, setPageLoading ] = useState(true)
    const [ pageerror, setPageError ] = useState(false)

    const [ loading, setLoading ] = useState(false)
    const [ tagOpen, setTagOpen ] = useState(false)
    const [ tagsOpen, setTagsOpen ] = useState(false)
    const [ editTagOpen, setEditTagOpen ] = useState(false)
    const [ editTagId, setEditTagId ] = useState(0)

    const [ postTags, setPostTags ] = useState([])
    const [ blocks, setBlocks ] = useState()
    const [ tags, setTags ] = useState([])
    const [ authors, setAuthors ] = useState([])
    const [ basicInfoOpen, setInfoOpen ] = useState(true)
    const [ twitter, setTwitter ] = useState(0)
    const [ instagram, setInstagram ] = useState(0)
    const [ facebook, setFacebook ] = useState(0)
    const [ youtube, setYoutube ] = useState(0)
    const [ tiktok, setTiktok ] = useState(0)
    const [ currentUser, setCurrentUser] = useState()
    const [ updatedAuthor, setUpdateAuthor ] = useState('')
    const [ updateDate, setUpdateDate ] = useState('')
    const [ createdAuthor, setCreateAuthor ] = useState('')
    const [ createDate, setCreateDate ] = useState('')
    const errorText = false

    const handleTag = event => setTag(event.target.value)
    const handlePost_image = event => setPost_image(event.target.value)
    const handleSummary = event => setSummary(event.target.value)
    const handleTitle = event => setTitle(event.target.value)
    const handleTagName = event => setTagName(event.target.value)
    const handlePostAuthor = event => setPostAuthor(event.target.value)

    const toggleTagOpen = event => setTagOpen(!tagOpen)
    const toggleEditTagOpen = event => setEditTagOpen(!editTagOpen)

    const toggleTagsOpen = event => setTagsOpen(!tagsOpen)

    const toggleImgOpen = event => setImgOpen(!imgOpen)
    const toggleDeleteOpen = event => setDeleteOpen(!deleteOpen)
    const handleClose = () => setSnack(false)
    const handleFeatured = () => setFeatured(!featured)
    const toggleInfoOpen = event => setInfoOpen(!basicInfoOpen)
    const handleSubscription = () => setSubscription(!subscription)


    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    // functions
    
    const updatePost1 = async () => {
      const name = localStorage.getItem('name')
      if(name===0){
         showSnack('error', 'Please visit your profile and add your name.')
         return
      }
      if(title.length>0 && summary.length>0 && post_image.length>0 && blocks!==null && blocks.blocks.length>1){
        setLoading(true)
  
        const updated_author=localStorage.getItem('name')

        const update = {
          title,
          summary,
          blocks,
          post_image,
          subscription: subscription ? 1 : 0,
          featured: featured ? 1 : 0,
          author_id: postAuthor,
          updated_author, 
          id: parseInt(id),
        }
  
        try {
          const data = await updatePost(update, currentUser.accessToken)
          setUpdateAuthor(updated_author)
          const dateStr = moment(data.updated_date).format('MMMM Do YYYY, h:mm a');
          setUpdateDate(dateStr)
          showSnack('success', 'Post updated!')
        } catch(error){
        
          showSnack('error', 'Error updating post!')
        } finally {
          setLoading(false)
        }
      } else {
        showSnack('error', 'Please add text in the required fields')
      }
    }

    const getSocialUpdate = (social) => {
      if(social==='facebook') return {facebook: facebook===1 ? 0 : 1, twitter, instagram, tiktok,  youtube}
      if(social==='twitter') return {twitter: twitter===1 ? 0 : 1, facebook, instagram, tiktok, youtube}
      if(social==='youtube') return {youtube: youtube===1 ? 0 : 1, twitter, facebook, instagram, tiktok}
      if(social==='instagram') return {instagram: instagram===1 ? 0 : 1, twitter, facebook, tiktok, youtube}
      if(social==='tiktok') return {tiktok: tiktok===1 ? 0 : 1, twitter, facebook, instagram, youtube}
    }

    const updateSocial = async (social) => {
      const update = getSocialUpdate(social)

      try {
        await updatePostSocials({...update, id: parseInt(id) }, currentUser.accessToken)
        showSnack('success', `Success! ${social}`)
        if(social==='facebook'){
          setFacebook(facebook===1 ? 0 : 1)
          return 
        }

        if(social==='twitter'){
          setTwitter(twitter===1 ? 0 : 1)
          return 
        }
        if(social==='youtube'){
            setYoutube(youtube===1 ? 0 : 1)
          return 
        }
        if(social==='instagram'){
          setInstagram(instagram===1 ? 0 : 1)
          return 
        }

        if(social==='tiktok'){
          setTiktok(tiktok===1 ? 0 : 1)
          return 
        }
       
      } catch(error){
        showSnack('error', 'Error adding tag!')
      }
    }

    const publishPost1 = async () => {
      setLoading(true)
      const published_author = localStorage.getItem('name')

      const update = {
        published: published ? 0 : 1,
        published_author,
        published_author_id: postAuthor,
        subscription: subscription ? 1 : 0,
        id: parseInt(id),
      }
      
      try {
        const data = await publishPost(update, currentUser.accessToken)
    
        setPublished(!published)
        const dateStr = moment(data.published_timestamp).format('MMMM Do YYYY, h:mm a');
        setPublishDate(dateStr)
        setPublishAuthor(published_author)
        showSnack('success', 'Post published!')
      } catch(error){
        showSnack('error', 'Error updating post!')
      } finally {
        setLoading(false)
      }
    }

    const addPostTag = async () => {
      if(!postTags.includes(tag)){
        const newPostTag = {post_id: id, tag_id: tag}
     
        try {
          await createPostTags(newPostTag, currentUser.accessToken)
          
          setPostTags([...postTags, tag])
        } catch(error){
          showSnack('error', 'Error adding tag!')
        }
      }
    }

    const deletePostTag1 = async tag_id => {
      try {
        await deletePostTag({
            tag_id, 
            post_id: id,
          }, 
          currentUser.accessToken)
        const newtags = postTags.filter(p => p!==tag_id)
        setPostTags(newtags)
      } catch(error){
        showSnack('error', 'Error adding tag!')
      }
    }

    const deleteTag1 = async tag_id => {
      toggleTagOpen()
      try {
        await deleteTag({
            tag_id
          },
         currentUser.accessToken)
         const newtags = tags.filter(p => p.id!==tag_id)

          setTags(newtags)
          setTag(newtags[0].id)
          const newposttags = postTags.filter(p => p!==tag_id)
          setPostTags(newposttags)
      } catch(error){
        showSnack('error', 'Error adding tag!')
      }
    }

    const chooseEditTag = tag_id => {
      setEditTagId(tag_id)
      toggleEditTagOpen()
      setTagsOpen(false)
      setTagOpen(false)
    }    

    const updateTag1 = async (tag_id, tagName, tagColor) => {
      toggleEditTagOpen()

      const tagUpdate = {
        tagName, 
        tagColor,
        tag_id
      }

      try {
        await updateTag(tagUpdate, currentUser.accessToken)

        const newtags = tags.filter(p => p.id!==tag_id)

        const addTag = {
          tag_name: tagName, 
          tag_color: tagColor, 
          id: tag_id,
        }

        const tagList = [...newtags, addTag]

        const firstTag = tagList[0].id

        setTags(tagList)
        setTag(firstTag)
        setEditTagId(0)
  
        showSnack('success', 'Tag updated!')
      } catch(error){
        showSnack('error', 'Error adding tag!')
      }
    }

    const deletePost1 = async () => {
      toggleDeleteOpen()
      try {
        await deletePost({id},  currentUser.accessToken)
        history.push(`/user/posts`)
      } catch(error){
        showSnack('error', 'Error deleting post!')
      }
    }

    const newTag = async () => { 
      toggleTagOpen()
      const token = currentUser.accessToken
      try {
        const data = await createTag({
          tagName,
          tagColor,
        }, token)

        if(data.tag_id){
          
          const newt = {
            id: data.tag_id,
            tag_name: tagName,
            tag_color: tagColor,
          }
      
          const tagList = [...tags, newt]
          const firstTag = tagList[0].id
 
          setTags(tagList)
          setTagName('')
          setTag(firstTag)
          showSnack('success', data.message)
        } else {
          showSnack('error', 'Error adding tag!')
        }
      } catch(error){
        showSnack('error', error)
      } finally{
        setLoading(false)
      }
    }

    const launchPreview = () => window.open(`/post/${id}`);

    const handleSave = useCallback(async () => {
      // retrieve data inserted
      const savedData = await editorCore.current.save();
      // save data
      setBlocks(savedData);
    }, [setBlocks]);

    const handleInitialize = useCallback((instance) => {
      // await instance._editorJS.isReady;
      instance._editorJS.isReady
        .then(() => {
          // set reference to editor
          editorCore.current = instance;
        })
    }, []);

    useEffect(()=>{
     
      // set fetched data
        async function setData(token){

          const author_id = localStorage.getItem('author_id')

          try {

            const data = await getUpdatePostData({id}, token)
        
            setPageLoading(false)
            
            if(data && data.activeAuthors.length>0){
              setAuthors(data.activeAuthors)
              setPostAuthor(author_id)
            }
    
            if(data && data.tags.length>0){
              setTag(data.tags[0].id)
              setTags(data.tags)
            }

            if(data && data.post){
         
              const post = data.post
              const feat = post.featured===1 ? true : false
              const sub = post.subscription===1 ? true : false

              const pub = post.published===1 ? true : false
              const pTags = post ? post.postTags.map(t => t.tag_id) : []
              if(pTags.length>0){
                setPostTags(pTags)
              }
              const blk = JSON.parse(post.blocks)
      
              setTitle(post.title)  
              setSummary(post.summary)
              setPost_image(post.post_image)
              setFeatured(feat)
              setPublished(pub)
              setSubscription(sub)
              setTiktok(post.tiktok)
              setTwitter(post.twitter)
              setYoutube(post.youtube)
              setInstagram(post.instagram)
              setFacebook(post.facebook)
              if(pub){
                setPublishAuthor(post.published_author)
                const dateStr = moment(post.published_date).format('MMMM Do YYYY, h:mm a');
                setPublishDate(dateStr)
              
              }
              setBlocks(blk)
              if(post.update_author){
                setUpdateAuthor(post.update_author)
              }
              const dateStr = moment(post.updated_timestamp).format('MMMM Do YYYY, h:mm a');
              setUpdateDate(dateStr)


              const createdateStr = moment(post.created_timestamp).format('MMMM Do YYYY, h:mm a');
              setCreateDate(createdateStr)
              if(post.created_author){
                setCreateAuthor(post.created_author)
              }
              if(post.created_author){
                setCreateAuthor(post.created_author)
              }

              if(post.author_id){
                  setPostAuthor(post.author_id)
              } else {
                if(data.activeAuthors.length>0){
                  setPostAuthor(data.activeAuthors[0].id)
                }
              }
            }
        } catch(error){
          setPageError(true)
        } finally{
          setPageLoading(false)
        }
    
      }

      if(currentUser){
        setPageLoading(true)
        setData(currentUser.accessToken)
      } 
     
      const unsubscribe = auth.onAuthStateChanged(user => {
        if(user){
          setCurrentUser(user)
          if(currentUser===null){
            setPageLoading(true)
            setData(user.accessToken)
          } 
        } else {
          history.push('login')
        }
      })
      return unsubscribe
    },[id, currentUser])

    const baseUrl  = localStorage.getItem('base_url')
    const slug = title.length>0 ? slugify(title) : ''
    const url = `${baseUrl}/${slug}`

    if(pageloading) return <div />
    if(!pageloading && pageerror)  return <Alert severity="error" >Loading error!</Alert>
    
    return (
      <FadeIn>
      <main >
        <Grid container  
              alignItems="center" 
              spacing={2} 
              justifyContent='space-between'
        >   
          <Grid item xs={12}> 
          {basicInfoOpen ?
            <Grid container  
                  alignItems="center" 
                  spacing={2} 
                  justifyContent='space-between'
            >
                 <Grid item xs={12} md={9} lg={10}> 
                  <FormControl sx={{ width: '100%'}}>
                    <BlogInput 
                      required={true}
                      errorText={errorText}
                      title={title}
                      handleTitle={handleTitle}
                      label="Title" 
                    />
                  </FormControl>  
                </Grid>
                <Grid item xs={12} md={3} lg={2}> 
                  {authors.length>0 &&
                    <AuthorDropDown
                      authors={authors}
                      postAuthor={postAuthor}
                      handlePostAuthor={handlePostAuthor}
                    />
                  }
                </Grid>
                <Grid item xs={12}> 
                  <BlogInput 
                    required={true}
                    errorText={errorText}
                    title={summary}
                    handleTitle={handleSummary}
                    label="Summary" 
                  />
                </Grid>  
                <Grid item xs={12}> 
                  <Grid container  
                      alignItems="center" 
                      spacing={2} 
                      justifyContent='space-between'
                  >
                    <Grid item >
                      <Grid container  
                        alignItems="center" 
                        spacing={2} 
                        justifyContent='space-between'
                    >
                      {tags.length>0 &&
                        <Grid item  >
                          <TagDropDown 
                            tag={tag}
                            tags={tags}
                            handleTag={handleTag}
                          />
                        </Grid>
                      }
                      <Grid item> 
                        <BasicIconButton 
                          size='small'
                          title='Post Tag'
                          actionFunc={addPostTag}
                          icon={<AddIcon />}  
                          />
                      </Grid>
                     
                      {postTags.length>0 && 
                        <Grid item > 
                          <Grid container spacing={3} alignItems='center'>
                            {postTags.map(p =>  
                              <PostTag 
                                key={p} 
                                tags={tags} 
                                tag_id={p} 
                                deletePostTag={deletePostTag1} 
                              />
                            )}
                          </Grid>
                        </Grid>
                      }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> 
                <Grid item xs={12}> 
                  <Grid container  
                      alignItems="center" 
                      spacing={2} 
                      justifyContent='space-between'
                  >
                    <Grid item >
                      <Grid container  
                        alignItems="center" 
                        spacing={2} 
                        justifyContent='space-between'
                    >
                      <Grid item> 
                        <BasicIconButton 
                          size='small'
                          title='Tags'
                          actionFunc={toggleTagsOpen}
                          icon={<ListIcon />}  
                          />
                      </Grid>
                      <Grid item> 
                        <BasicIconButton 
                          size='small'
                          title='Add Tag'
                          actionFunc={toggleTagOpen}
                          icon={<AddIcon />}  
                          />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item >
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item>
                        <FormGroup>
                          <FormControlLabel 
                            control={<Switch 
                            checked={featured} 
                            onChange={handleFeatured}/> }  
                            label="Featured" 
                          />
                        </FormGroup>      
                      </Grid>
                      <Grid item>
                        <FormGroup>
                          <FormControlLabel 
                            control={<Switch 
                            checked={subscription} 
                            onChange={handleSubscription}/> }  
                            label="Subscriber" 
                          />
                        </FormGroup>      
                      </Grid>
                      {published &&
                      <Grid item>
                        |
                      </Grid>
                      }
                      {published &&
                        <Grid item>
                          <Tooltip title="Published Url">
                            <Link href={url} target='_blank'>
                              <LinkIcon  />
                            </Link>
                          </Tooltip>
                        </Grid>
                      }       
                      {published &&
                        <Grid item>
                          <Grid container spacing={1} alignItems='center'>
                            <Grid item> 
                              <Tooltip title="Twitter">
                                <IconButton onClick={() => updateSocial('twitter')}>
                                  <TwitterIcon color={twitter===1  ? 'primary' : 'disabled'} />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Facebook">
                                <IconButton onClick={() => updateSocial('facebook')}>
                                  <FacebookIcon color={facebook===1  ? 'primary' : 'disabled'} />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Instagram">
                                <IconButton onClick={() => updateSocial('instagram')}>
                                  <InstagramIcon color={instagram===1  ? 'primary' : 'disabled'} />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Youtube">
                                <IconButton onClick={() => updateSocial('youtube')}>
                                  <YouTubeIcon color={youtube===1  ? 'primary' : 'disabled'} />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Tiktok">
                                <IconButton onClick={() => updateSocial('tiktok')}>
                                  <TikTokIcon color={tiktok===1  ? '#6415ff' : 'lightgrey'} />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                  </Grid>
                  </Grid> 
                </Grid>
              </Grid> 

              <Grid item  xs={12}> 
                  <Grid container justifyContent='space-between' spacing={1} >
                    <Grid item md={8} xs={12} > 
                      <BlogInput 
                        required={true}
                        errorText={errorText}
                        title={post_image} 
                        handleTitle={handlePost_image}
                        label="Post Image" 
                      />
                    </Grid>

                    <Grid item >
                      {post_image.length>0 ?
                        <img onClick={toggleImgOpen}  src={post_image} alt='selected pick' height='60' style={{borderRadius: 5, cursor: 'pointer'}}/>
                        :
                        <ImageIcon sx={{ fontSize: 36 }}  color='primary'/>
                      }
                    </Grid> 
                    <Grid item xs={12}>
                      <Grid container spacing={2}  alignItems='center' >
                        {imageSites.map(s => 
                          <Grid item key={s.title}>
                            <Link href={s.url} target="_blank" color='#6415ff' variant='body2'  >
                              {s.title}
                            </Link>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{mt:1, mb: 1}} />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item>
                          <Grid container spacing={2} justifyContent='space-between' alignItems='center' >
                            <Grid item>
                              <Typography>
                                Created: {createDate} - {createdAuthor}
                              </Typography>       
                            </Grid>
                            <Grid item>
                              <Typography>
                                Updated: {updateDate} - {updatedAuthor}
                              </Typography>       
                            </Grid>
                            <Grid item>
                              <Typography>
                                Published:  {published &&  `${publishDate} -  ${publishAuthor}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>    
                  </Grid>
                </Grid>
              </Grid> 
            : 
            <Grid container alignItems='flex-start' justifyContent='space-between'>
              <Grid item  >
                <Typography variant="h6">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container 
                      spacing={2} 
                      alignItems='flex-start' 
                      justifyContent='space-between'
                >

                </Grid>
              </Grid>

            </Grid>
            }
            <Grid item  xs={12}> 
              <Divider sx={{mt:2, mb:2}} />
            </Grid>        
            <Grid item>
              <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
                <Grid item  >
                  {published ?
                    <LoadingIconButton2
                      size='small'
                      onClick={publishPost1}
                      title='Unpublish'
                      loading={loading}
                      icon={<UnpublishedIcon />}
                    /> 
                    :
                    <LoadingIconButton1
                      size='small'
                      onClick={publishPost1}
                      title='Publish'
                      loading={loading}
                      icon={<PublishIcon />}
                    /> 
                  }
                </Grid>
                <Grid item >
                  <Button 
                    fullWidth
                    variant="contained"
                    color='warning'
                    size='small'
                    onClick={launchPreview}
                    startIcon={<PreviewIcon /> }
                  >
                    Preview
                  </Button>
                </Grid>
                <Grid item   >
                  <Button
                    variant="contained"
                    fullWidth
                    color='error'
                    size='small'
                    onClick={toggleDeleteOpen}
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </Grid>
                <Grid item   >
                  <LoadingIconButton
                    size='small'
                    onClick={updatePost1}
                    title='Update'
                    loading={loading}
                    icon={<EditIcon />}
                  /> 
                </Grid>
                <Grid item   >
                  <BasicIconButton 
                    fullWidth={true}
                    size='small'
                    title={basicInfoOpen ? 'Collapse' : 'Enlarge'}
                    actionFunc={toggleInfoOpen}
                    icon={basicInfoOpen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}  
                  />
                </Grid>

              </Grid>

            </Grid>
            <Grid item  xs={12}> 
              <Divider sx={{mt:2, mb:2}} />
            </Grid>        
            <Grid item xs={12}> 
              {blocks &&
                <ReactEditorJS 
                  defaultValue={blocks} 
                  tools={EDITOR_JS_TOOLS} 
                  onChange={handleSave}
                  onInitialize={handleInitialize}
                />
              }
            </Grid>
          </Grid>
        </Grid>
        <AddTagDialog  
          handleTagColor={setTagColor}
          tagColor={tagColor}
          handleTagName={handleTagName}
          tagName={tagName}
          errorText={errorText}
          newTag={newTag}
          tagOpen={tagOpen}
          toggleTagOpen={toggleTagOpen}
          deleteTag={deleteTag1}
          tags={tags}
        />
        <EditTagDialog  
          tagOpen={editTagOpen}
          toggleTagOpen={toggleEditTagOpen}
          editTag={updateTag1}
          tags={tags}
          tagId={editTagId}
        />
         <TagsDialog 
          tagOpen={tagsOpen}
          toggleTagOpen={toggleTagsOpen}
          deleteTag={deleteTag1}
          chooseEditTag={chooseEditTag}
          tags={tags}
        />
        <SnackAlert 
          snack={snack}
          handleClose={handleClose}
          msgType={msgType}
          alertMsg={alertMsg}
        /> 
         <Modal
            open={imgOpen}
            onClose={toggleImgOpen}
          >
              <Card sx={style}>
                <CardMedia
                  component="img"
                  height="100%"
                  image={post_image}
                  alt={title}
                />
                </Card>
          </Modal>
          <Dialog
            open={deleteOpen}
            onClose={toggleDeleteOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete post?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deletion will be permanent. You can also unpublish posts.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={deletePost1}>Delete</Button>
              <Button onClick={toggleDeleteOpen} autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </FadeIn>
    )
}