import React from 'react'

// UI

// mui components
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import EditIcon from '@mui/icons-material/Edit'

// custom components
import LoadingIconButton from '../Buttons/LoadingIconButton'

export default function UpdateSocials({
  twitter, 
  facebook,
  handleTwitter,
  handleFacebook,
  updateSite,
  loading,
  handleInstagram,
  handleDiscord,
  handleTiktok,
  handleYoutube,
  instagram,
  discord,
  tiktok,
  youtube,
}){

    return(
    <Card>
      <CardContent>
        <Typography variant={'h5'} color='primary'>
          Socials
        </Typography>
      </CardContent>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <CardContent>
        <TextField 
          value={twitter} 
          onChange={handleTwitter} 
          sx={{ width: '100%'}}
          label='Twitter'
        />
      </CardContent>
      <CardContent>
        <TextField 
          value={facebook} 
          onChange={handleFacebook} 
          sx={{ width: '100%'}}
          label='Facebook'  
        />
      </CardContent>

      <CardContent>
        <TextField 
          value={instagram} 
          onChange={handleInstagram} 
          sx={{ width: '100%'}}
          label='Instagram'  
        />
      </CardContent>

      <CardContent>
        <TextField 
          value={discord} 
          onChange={handleDiscord} 
          sx={{ width: '100%'}}
          label='Discord'  
        />
      </CardContent>

      <CardContent>
        <TextField 
          value={tiktok} 
          onChange={handleTiktok} 
          sx={{ width: '100%'}}
          label='Tiktok'  
        />
      </CardContent>

      <CardContent>
        <TextField 
          value={youtube} 
          onChange={handleYoutube} 
          sx={{ width: '100%'}}
          label='Youtube'  
        />
      </CardContent>


      <CardContent>
        <LoadingIconButton
          loading={loading}
          icon={<EditIcon />}
          onClick={updateSite} 
          title='Submit'
        />
      </CardContent> 
    </Card>
  )
}