import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

const LoginHeader = ({
    title,
}) => 
    <Grid container 
          justifyContent='center' 
          textAlign='center'
          spacing={2}
    >
        <Grid item xs={12}>
            {/* <Logo /> */}
        </Grid>
        <Grid item xs={12}>
            <Typography 
                variant="h5" 
                sx={{color:'#6415ff'}}
            >
                {title}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>  
    </Grid>

export default LoginHeader