import React, { useState, useEffect, useRef, useCallback } from "react"
import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../../tools'
import moment from 'moment'

// mui
import Grid from '@mui/material/Grid'
import { Divider, Typography} from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'

// custom components

import LoadingIconButton from '../Buttons/LoadingIconButton'

export default function LegalPage({
    blocks,
    updateDate,
    updateDoc,
    title,
    loading,
}){

    const ReactEditorJS = createReactEditorJS()

    const editorCore = useRef(null);

    // DATA
    
    // fetching

    // state management

    const [ blocks1, setBlocks ] = useState()

    const updateDocument = () => updateDoc(blocks1)

    // functions
    
    const handleSave = useCallback(async () => {
      // retrieve data inserted
      const savedData = await editorCore.current.save();
      // save data
      setBlocks(savedData);
    }, [setBlocks]);

    const handleInitialize = useCallback((instance) => {
      // await instance._editorJS.isReady;
      instance._editorJS.isReady
        .then(() => {
          // set reference to editor
          editorCore.current = instance;
        })
    }, []);

    useEffect(() => {

        setBlocks(blocks)
    
    },[blocks])

    const updateDateStr = updateDate ? moment(updateDate).format('MMMM Do YYYY')  : 'Never'
    console.log(blocks)
    return (

        <Grid container  
              alignItems="center" 
              spacing={2} 
              justifyContent='space-between'
        >   
          <Grid item> 
            <Typography variant="h6">
                {title}
            </Typography>
          </Grid>
          <Grid item> 
            <Typography variant="h6">
                Last updated: {updateDateStr}
            </Typography>
          </Grid>
          <Grid item   >
            <LoadingIconButton
                size='small'
                onClick={updateDocument}
                title='Update'
                loading={loading}
                icon={<EditIcon />}
                /> 
            </Grid>
          
            <Grid item  xs={12}> 
              <Divider sx={{mt:2, mb:2}} />
            </Grid>        
            <Grid item xs={12}> 
                <ReactEditorJS 
                    defaultValue={blocks} 
                    tools={EDITOR_JS_TOOLS} 
                    onChange={handleSave}
                    onInitialize={handleInitialize}
                />
            </Grid>
          </Grid>

    )
}