import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
// packages
import validator from 'validator'

// DATA

// clients
import {app} from '../firebase'

import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  GoogleAuthProvider, 
  signInWithPopup,
 } from "firebase/auth";

// queries
import {
  createMember,
} from '../queries/memberQueries'

import {
  getSite,
} from '../queries/siteQueries'

// UI

// custom components
import SignUpAllPage from '../components/Pages/SignUpAllPage'


export default function SignUpAll(){

  const history = useHistory()
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();

  // state
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ errorMsg, setErrorMsg] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)

  const handleEmail = event => setEmail(event.target.value)
  const handleClose = () => setSnack(false)
  const handlePassword = event => setPassword(event.target.value)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const inputs = [
    {
        name:'email',
        label:'Email Address',
        handleFunc: handleEmail,
        value: email
    },
    {
        name:'password',
        label:'Password',
        handleFunc: handlePassword,
        value: password
    }
  ]


  // functions
  const signUpEmail = async () => {

    if (!validator.isEmail(email)) {
      setErrorMsg('Invalid email!')
      return
    } 

    setLoading(true)

    const sites = await getSite()
    if(sites.length>0){
      showSnack('error','The site has already been created!')
      setTimeout(() => {
        history.push('/signin')
      }, 1000)
    
      return 
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)

      const user = userCredential.user
      
      if(user){
        try {
          await createMember({...user, role: 'admin'})
          showSnack('success',  'Success! Check your email to confirm your email address.')
        } catch(error){
          setErrorMsg(error.message)
        }
      } else {
        setErrorMsg('Sign up error!')
      } 
    } catch(error) {

      setErrorMsg(error.message)
      throw error
    } finally{
      setLoading(false)
    }
  }

  const googleSignUp = async () => {
    try {
      const userCredential = await signInWithPopup(auth, provider)
      
      const user = userCredential.user
      await createMember({...user, role: 'admin'})
      showSnack('success',  'Success! Check your email to confirm your email address.')
    
    } catch(error) {
      setErrorMsg(error.message)
      throw error
    } finally{
      setLoading(false)
    }
    
  }

  return <SignUpAllPage  
          inputs={inputs} 
          signUpEmail={signUpEmail} 
          googleSignUp={googleSignUp}
          errorMsg={errorMsg} 
          loading={loading}
          snack={snack}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
        />
}