import React from 'react'
import { HexColorPicker, HexColorInput } from "react-colorful";

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Close } from '@mui/icons-material';

import { Grid, Typography, Box, IconButton, Divider } from '@mui/material'
import BlogInput from '../Inputs/BlogInput'

export default function AddTagDialog({
    errorText,
    tagOpen,
    toggleTagOpen,
    newTag,
    handleTagColor,
    handleTagName,
    tagName,
    tagColor,
}) {
    return (
      <Dialog open={tagOpen} onClose={toggleTagOpen}>
        <DialogTitle>
          Add Tag
          <IconButton
            aria-label="close"
              onClick={toggleTagOpen}
              sx={{
                position: 'absolute',
                right: 10,
                top: 10,
                color: (theme) => theme.palette.grey[500],
              }}
            >
          <Close />
        </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Choose a tag name and color (hex). 
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item  >
              <BlogInput 
                errorText={false}
                title={tagName}
                handleTitle={handleTagName}
                label="Tag Name" 
              />
            
              <Grid container spacing={2} sx={{mt:2}} alignItems='center'>
                <Grid item>
                  <Box style={{
                      backgroundColor: tagColor, 
                      height: 30, 
                      width: 30, 
                      borderRadius: 5,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant='h6' color={tagColor}>
                    {tagColor}
                  </Typography>
              </Grid>
              </Grid>
            </Grid>
            <Grid item  >
              <HexColorPicker color={tagColor} onChange={handleTagColor} />
              <Box sx={{mt: 2}}/>
              <HexColorInput color={tagColor} onChange={handleTagColor} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleTagOpen}>
              Cancel
          </Button>
          <Button onClick={newTag}>
              Add Tag
          </Button>
        </DialogActions>
      </Dialog>
  )
}
