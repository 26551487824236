import React from 'react'
import moment from 'moment'
import { Link as RouterLink } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Chip from '@mui/material/Chip'

import LinkIcon from '@mui/icons-material/Link'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon  from '@mui/icons-material/Instagram'

import {TikTokIcon} from '../ImageElements/BlogIcons'

export default function PostListItem({
    id,
    title,
    published_timestamp,
    updated_timestamp,
    created_timestamp,
    post_tags,
    featured,
    author_name,
    author_id,
    published,
    subscription,
    twitter,
    facebook,
    youtube,
    instagram,
    tiktok,
    slug,
}){

    const date = created_timestamp && created_timestamp>0 ? moment(created_timestamp).format('MMMM Do YYYY') : ''
    const update = updated_timestamp && updated_timestamp>0 ? moment(updated_timestamp).format('MMMM Do YYYY') : ''
   
    const baseUrl = localStorage.getItem('base_url')
    const url = `${baseUrl}/${slug}`
    const pub = published===1 ? true : false
    const feat= featured===1 ? true : false
    const sub = subscription===1 ? true : false

    return  <Grid item xs={12}>
                <Typography >
                    <Link 
                        component={RouterLink} 
                        to={`/user/update_post/${id}`}  
                        style={{color: '#6415ff'}}
                    >
                        {title}
                    </Link>
                </Typography>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item  xs={12}>
                        <Typography variant="body2">
                          <span>{date} ({update})</span> by <Link
                                            component={RouterLink} 
                                            sx={{color: '#5d4037'}} 
                                            to={`/user/author_posts/${author_id}`}
                                        >
                                                {author_name}
                                        </Link> 
                        </Typography> 
                    </Grid>

                    <Grid item  xs={12}>
                        <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
                            <Grid item>
                                <Grid container spacing={1} alignItems='center'>
                                    {post_tags.length> 0 && post_tags.map(t => 
                                        <Grid item key={t.tag_name}>
                                            <Link  
                                                variant='body2'
                                                key={t.id}  
                                                to={`/user/tag_posts/${t.tag_id}`} 
                                                color={t.tag_color} 
                                                component={RouterLink}
                                            >
                                                {t.tag_name}
                                            </Link>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container spacing={1} alignItems='center'>
                                    {pub && 
                                        <Grid item>
                                            <Link href={url} target='_blank'>
                                                <LinkIcon  />
                                            </Link>
                                        </Grid>
                                    }
                                    {feat && 
                                        <Grid item>
                                            <Chip label='Featured' size='small' color='info'  sx={{ color:'white'}} />
                                        </Grid>
                                    }
                                    {sub && 
                                        <Grid item>
                                            <Chip label='Subscription' size='small' color='success'  sx={{ color:'white'}} />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    {pub && 
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item>
                                            <TwitterIcon color={twitter && twitter===1 ? 'primary' : 'disabled'} />
                                        </Grid>
                                        <Grid item>
                                            <FacebookIcon color={facebook && facebook===1 ? 'primary' : 'disabled'}   />
                                        </Grid>
                                        <Grid item>
                                            <InstagramIcon color={instagram && instagram===1 ? 'primary' : 'disabled'}  />
                                        </Grid>
                                        <Grid item>
                                            <YouTubeIcon color={youtube && youtube===1 ? 'primary' : 'disabled'}  />
                                        </Grid>
                                        <Grid item>
                                            <TikTokIcon color={tiktok && tiktok===1 ? '#6415ff' : 'lightgrey'} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
          
}