import React from 'react';
import Link from '@mui/material/Link'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const MainItem = ({
  title,
  published_date,
  tags,
}) => {
  return <Grid item xs={12} >
          <Typography variant="caption">
            {moment(published_date).format('MMMM Do, YYYY')} 
          </Typography>
          <Box sx={{mb:1}}>
            <Link href='' variant="h6" color='primary'  >
              {title}
            </Link>
          </Box>
          <Grid container spacing={2}>
            {tags.length>0 && tags.map(t => 
              <Grid item key={t.tag_name}>
                <Link  href={`/tag/${t.tag_id}/page/1`} sx={{color: t.tag_color}} variant='body1'>
                  {t.tag_name}
                </Link>
              </Grid>
              )}
          </Grid>
       
          {/* <Typography variant="body1" xs={12} color='textSecondary' sx={{ display: { xs: 'none', md: 'block' } }}>
            {summary}
          </Typography> */}
        </Grid>

}

export default function Main({
    posts,
}) {
  
  const title = 'Recent Articles'
  const nonFeaturedpost = posts.filter(p => p.featured===0)

  return (
    <Grid
      item
      xs={12}
      md={8}
    >
      <Typography variant="h5" gutterBottom >
        {title}
      </Typography>
      <Divider sx={{ mb:2}} />
      <Grid container  spacing={3}>
      {nonFeaturedpost.map((p) => 
        <MainItem {...p} key={p.title} />
      )}
      </Grid>
    </Grid>
  );
}
