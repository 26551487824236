import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

import MainFeaturedPost from '../Blog/MainFeaturedPost1';
import Main from '../Blog/Main';
import {SidebarPlain} from '../Blog/SideBar';

import BlogHeader from '../Blog/BlogHeader'
import FeaturedPosts from '../Blog/FeaturedPosts'
import Footer from '../Blog/Footer'

export default function BlogPreviewPage({
  posts,
  featuredList,
  site,
  tags,
}) {
    const featuredPosts = featuredList
    const mainFeaturedPost1 = featuredPosts.length>0 && featuredPosts[0]
    const otherFeatures = featuredPosts.length>0 ? featuredPosts.slice(1): []
    const siteName = site ? site.site_name : ''
    const siteLogo = site ? site.site_logo : ''
    const siteDescription = site ? site.description : ''
    const siteTwitter = site ? site.twitter : ''
    const siteFacebook = site ? site.facebook : ''
    const siteinstagram = site ? site.instagram : ''
    const sitediscord = site ? site.discord : ''
    const siteyoutube = site ? site.youtube : ''
    const adminEmail = site ? site.admin_email : ''
    console.log(posts)
    return (
        <Container maxWidth="lg">
          <div style={{minHeight: 400, width: '100%'}}>
             <BlogHeader 
              siteName={siteName} 
              siteLogo={siteLogo}
              tags={tags}
            />
            <Divider sx={{mt: 1, mb: 2}} /> 
            <MainFeaturedPost post={mainFeaturedPost1} />
            <Grid container >
              {otherFeatures.length>0 &&
                <FeaturedPosts otherFeatures={otherFeatures} />
              }
            </Grid>
          </div>
          <Grid container spacing={5} sx={{mt:1}} >
              <Main title="Recent articles" posts={posts} />
              <SidebarPlain
                siteDescription={siteDescription}
                siteTwitter={siteTwitter}
                siteFacebook={siteFacebook}
                siteinstagram={siteinstagram}
                sitediscord={sitediscord}
                siteyoutube={siteyoutube}
                adminEmail={adminEmail}
              />
          </Grid>
          <Footer />
        </Container>
    );
}