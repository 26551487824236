const baseUrl = process.env.REACT_APP_CF_WORKER

export const slugify = (...args) => {

  const value = args.join(' ')

  return value
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-') // separator
}

export const deployCF = async (token) => {
  
  try{
    return await postCF('/publish_edition', token, {})
  } catch(error){
    throw error
  }
}

export const postAuth = async (url = '', data = {}, token) => {

  try {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data) 
  });
  const res = await response.json(); 

  if(!response.ok){
    throw res
  }
  return res
  } catch(error) {
    throw error
  }
}

export const postCF = async (url = '', token, data = {}) => {

	const url1 = `${baseUrl}${url}`

	try {
		const data1 = await postAuth(url1, data, token)
    return data1
	} catch(error) {
		throw error
	}
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function langSwitch(lang){
  switch (lang) {
    case 'fr':
      	return "French";
    case 'de':
      	return "German";
    case 'es':
      return "Spanish";
    case 'en':
      	return "English";
    case 'it':
      	return "Italian";
    case 'pt':
      	return "Portuguese";
    case 'gr':
      	return "Greek";
    case 'ru':
        return "Russian";
    case 'zh':
      	return "Chinese";
    case 'jp':
      	return "Japanese";
    case 'sv':
      	return "Swedish";
    case 'da':
      	return "Danish";
	case 'nl':
		return "Dutch";
	case 'pl':
		return "Polish";
	case 'ro':
		return "Romanian";
	case 'ua':
		return "Urkanian";
	case 'tr':
		return "Turkish";
	case 'hu':
		return "Hungarian";
	case 'cz':
		return "Czech";


    default:
      return "English";
   }
}