import React from "react"
import { Route, Switch } from "react-router-dom"
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Signin from "./pages/Signin"
import Signup from "./pages/SignupAll"
import Auth from "./pages/Post"
import CreateSite from './pages/CreateSite'
import User from "./pages/User"
import NotFoundPage from './components/Pages/NotFoundPage'
import BlogPreview from './pages/BlogPreview'
import TagsList from './pages/TagsPreviewPage'
import AuthorList from "./pages/AuthorsPreviewPage"

import Post from "./pages/Post"
import { purpleTheme } from "./themes"

const theme = createTheme(purpleTheme)
// npx wrangler pages dev -- npm start --d1=BLOG_DB --persist
export const routes = [
  { path: "/", component: Signin, exact: true },
  { path: "/user",  component: User, exact: false },
  { path: "/signin",  component: Signin, exact: false },
  { path: "/signup",  component: Signup, exact: false },
  { path: "/create_site",  component: CreateSite, exact: false },
  { path: "/auth",  component: Auth, exact: false },
  { path: "/post/:id",  component: Post, exact: false },
  { path: "/author_preview/:author_id",  component: AuthorList, exact: false },
  { path: "/tags_preview/:tag_id",  component: TagsList, exact: false },
  { path: "/blog_preview",  component:  BlogPreview, exact: true },
]

export default function App(){
    return( 
    <ThemeProvider theme={theme}> 
          <Switch key="router">
            {routes.map(route => (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
            <Route path="*" component={NotFoundPage} />
          </Switch>
    </ThemeProvider>
  )
}