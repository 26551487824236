import React from 'react'
import FadeIn from 'react-fade-in'
// UI

// mui components
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import EditIcon from '@mui/icons-material/Edit'

// custom components
import SnackAlert from '../Dialogs/SnackAlert'
import LoadingIconButton from '../Buttons/LoadingIconButton'

const update = 'Update'
const namesub = 'Name'
const submit = 'Submit'
const emailsub = 'Email'

export default function ProfilePage({
  name1,
  email1,
  msgType,
  alertMsg,
  snack,
  loading,
  handleClose,
  handleName,
  handleEmail,
  updateProfile
}){

    return(
      <FadeIn delay={100}>
      <Grid container spacing={1}>
            <Grid item xs={12}> 
              <Card>
                <CardContent>
                  <Typography variant={'h5'} 
                    sx={{ color:'#6415ff' }}
                  >
                    {update} Profile
                  </Typography>
                </CardContent>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <CardContent>
                  <TextField 
                    required
                    value={name1} 
                    onChange={handleName} 
                    sx={{ width: '100%'}}
                    label={namesub}
                  />
                </CardContent>
                <CardContent>
                  <TextField 
                    value={email1} 
                    onChange={handleEmail} 
                    sx={{ width: '100%'}}
                    label={emailsub}  
                    required
                  />
                </CardContent>
                <CardContent>
                  <LoadingIconButton
                    loading={loading}
                    icon={<EditIcon />}
                    onClick={updateProfile} 
                    title={submit}
                  />
                </CardContent> 
              </Card>
            </Grid> 
            <SnackAlert 
              snack={snack}
              handleClose={handleClose}
              msgType={msgType}
              alertMsg={alertMsg}
            />
          </Grid>
      </FadeIn>
  )
}