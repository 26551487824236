import {postCF} from '../utils'

export const getSite = async (token) => {
    try {
        return await postCF(`/site_admin`, token)
    } catch(error){
        throw error
    }
}

export const getSiteTerms = async (token) => {
    try {
        return await postCF(`/get_site_terms`, token)
    } catch(error){
        throw error
    }
}

export const getSitePrivacy = async (token) => {
    try {
        return await postCF(`/get_site_privacy`, token)
    } catch(error){
        throw error
    }
}

export const updateSiteTerms = async (update, token) => {
    try {
        return await postCF(`/update_site_terms`, token, update)
    } catch(error){
        throw error
    }
}

export const updateSitePrivacy = async (update, token) => {
    try {
        return await postCF(`/update_site_privacy`,  token, update)
    } catch(error){
        throw error
    }
}

export const createSite =  async (newSite, token) => {

    try {
        return await postCF(`/create_site`, token, newSite)
    } catch(error){
        throw error
    }
}

export const updateSite = async (update, token) => {
    try {
        return await postCF(`/update_site`, token,  update)
    } catch(error){
        throw error
    }
}

export const updateSiteSocials = async (update, token) => {
    try {
        return await postCF(`/update_site_socials`, token,  update)
    } catch(error){
        throw error
    }
}


