import React from 'react'

const Logo = ({size = 8}) => {
  const logoUrl =  localStorage.getItem('site_logo')
  return (
      <img  src={logoUrl} 
            alt="langalogo"
            style={{
              height: size * 10,
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 16,
              marginBottom: 16,
              borderRadius: '10%',
          }}  
      />
  )
}
        

export default Logo
