import React from 'react'

// mui
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

// custom components
import SocialList from './SocialList'
import AboutCard from './AboutCard'
import Contact from './Contact'
import RecentArticles from './RecentArticles'

const Sidebar = ({
    siteDescription,
    siteTwitter,
    siteFacebook,
    adminEmail,
    recentPosts,
    taglang,
}) => {
    return  <Grid item xs={12} md={4}>
                <AboutCard 
                    siteDescription={siteDescription} 
                />
                <Grid>
                    <Divider sx={{mt:3}} />
                </Grid>
                <RecentArticles 
                    recentPosts={recentPosts}
                    taglang={taglang}
                />
                <Grid>
                    <Divider sx={{mt:3, mb: 3}} />
                </Grid>
                  <SocialList  
                    siteTwitter={siteTwitter}
                    siteFacebook={siteFacebook} 
                />
                <Contact 
                    adminEmail={adminEmail} 
                />
            </Grid>
}

export const SidebarPlain = ({
    siteDescription,
    siteTwitter,
    siteFacebook,
    siteinstagram,
    sitediscord,
    siteyoutube,
    sitetiktok,
    adminEmail,
}) => {
    
    return  <Grid item xs={12} md={4}>
                <AboutCard 
                    siteDescription={siteDescription} 
                />
                <Grid>
                    <Divider sx={{mt:3, mb: 3}} />
                </Grid>
                <SocialList  
                    siteTwitter={siteTwitter}
                    siteFacebook={siteFacebook} 
                    siteinstagram={siteinstagram}
                    sitediscord={sitediscord}
                    siteyoutube={siteyoutube}
                    sitetiktok={sitetiktok}
                />
                <Contact 
                    adminEmail={adminEmail} 
                />
                
            </Grid>
}

export default Sidebar