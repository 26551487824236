import React, {useEffect, useState} from 'react'
import {auth} from '../firebase'
import { useHistory } from "react-router-dom"

import {
    getFrontPagePreview
} from '../queries/blogQueries'

// UI
import { CircularProgress, Alert } from '@mui/material'

// custom components
import BlogPreviewPage from '../components/Pages/BlogPreviewPage'

export default function Blog(){

    let history = useHistory()

    const [postList, setpostList] = useState([])
    const [featuredList, setfeaturedList] = useState('')
    const [isLoading, setisLoading] = useState(true)
    const [ currentUser, setCurrentUser] = useState()

    const [isError, setisError] = useState('')
    const [site, setsite] = useState()
    const [tags, settags] = useState([])

    useEffect(() => {
        setisLoading(true)
        async function setData(token){

            try {
             const data = await getFrontPagePreview(token)
  
             if(data){
                setpostList(data.posts)
                setsite(data.site)
                settags(data.tags)
                const featuredList = data.posts.length>0 ? data.posts.filter(p => p.featured===1) : []
                setfeaturedList(featuredList)
             }
     
            } catch(error){
                setisError(true)
            } finally {
                setisLoading(false)
            }
        }


        if(currentUser){
            setData(currentUser.accessToken)
        } 
         
        const unsubscribe = auth.onAuthStateChanged(user => {
            if(user){
              setCurrentUser(user)
            } else {
              history.push('signin')
            }
          })
        return unsubscribe
    },[currentUser])


    if(!isLoading && isError)  return <Alert severity="error" >Loading error!</Alert>
    if(isLoading) return <CircularProgress />

    if(site!==null) return <BlogPreviewPage 
                                posts={postList} 
                                featuredList={featuredList}
                                isLoading={false} 
                                isError={false} 
                                error={'error'}  
                                site={site} 
                                tags={tags} 
                            />
}