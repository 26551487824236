import React from "react"

// mui
import Grid from '@mui/material/Grid'
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from "@mui/material"

export default function PostTag({
    tags, 
    tag_id,
    deletePostTag,
  }){
    const curTag =  tags.length>0 ? tags.filter(t => t.id===tag_id) : []
    const tag_name = curTag.length>0 ? curTag[0].tag_name : ''
    const tag_color = curTag.length>0 ? curTag[0].tag_color : ''
    const tagName = tag_name

    const deleteTag = () => deletePostTag(tag_id)
    return  <Grid item>
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <Box sx={{
                      backgroundColor: tag_color, 
                      height: 20, 
                      width: 20, 
                      borderRadius:1, 
                      mr:1,
                    }} 
                  />
              </Grid>
                <Grid item>
                  <Typography>
                    {tagName}
                  </Typography>
                </Grid>
                
              <Grid item>
                <IconButton size="small" onClick={deleteTag} >
                  <ClearIcon color="error" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
  }
  