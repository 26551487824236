import React, {useState, useEffect} from 'react'
import { HexColorPicker, HexColorInput } from "react-colorful";

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Close } from '@mui/icons-material';

import { Grid, Typography, Box, IconButton, Divider } from '@mui/material'
import BlogInput from '../Inputs/BlogInput'

export default function EditTagDialog({
    tagOpen,
    toggleTagOpen,
    editTag,
    tags,
    tagId,
}) {
    const [tagName, setTagName] = useState('')
    const [tagColor, setTagColor] = useState('')

    const handleTagName = event => setTagName(event.target.value)

    const editTag1 = () => editTag(tagId, tagName, tagColor)

    useEffect(()=> {
      if(tagId!==0){
        const tag = tags.filter(t => t.id===tagId)
        setTagColor(tag[0].tag_color)
        setTagName(tag[0].tag_name)
      }
    
    }, [tags, tagId])
    
    return (
      <Dialog open={tagOpen} onClose={toggleTagOpen}>
        <DialogTitle>
          Edit Tag
          <IconButton
            aria-label="close"
              onClick={toggleTagOpen}
              sx={{
                position: 'absolute',
                right: 10,
                top: 10,
                color: (theme) => theme.palette.grey[500],
              }}
            >
          <Close />
        </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Choose a tag name and color (hex). 
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item  >
              <BlogInput 
                errorText={false}
                title={tagName}
                handleTitle={handleTagName}
                label="Tag Name" 
              />
            
              <Grid container spacing={2} sx={{mt:2}} alignItems='center'>
                <Grid item>
                  <Box style={{
                      backgroundColor: tagColor, 
                      height: 30, 
                      width: 30, 
                      borderRadius: 5,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant='h6' color={tagColor}>
                    {tagColor}
                  </Typography>
              </Grid>
              </Grid>
            </Grid>
            <Grid item  >
              <HexColorPicker color={tagColor} onChange={setTagColor} />
              <Box sx={{mt: 2}}/>
              <HexColorInput color={tagColor} onChange={setTagColor} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleTagOpen}>
              Cancel
          </Button>
          <Button onClick={editTag1}>
              Edit Tag
          </Button>
        </DialogActions>
      </Dialog>
  )
}
