import * as React from 'react';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const PostTag = ({
  tag_id, 
  tag_name, 
  tag_color,
}) => {
  return  <Link href={`/tag/${tag_id}/page/1`} >
            <Button  size='small' style={{color: tag_color}}  >
              {tag_name} 
            </Button>
          </Link> 
}

const PostTags = ({ post_tags }) => {
  return post_tags && post_tags.length>0 ? post_tags.map(t =>  <PostTag key={t.tag_id} {...t} /> ) : <div />
}

export default PostTags