import {postCF} from '../utils'

export const getTags = async (token) => {
    try {
        return await postCF(`/tags`, token)
    } catch(error){
        throw error
    }
}

export const createTag =  async ({
    tagName,
    tagColor,
}, token) => {
    try {
        const data =  await postCF(`/create_tag`, token, {
            tag_name: tagName,
            tag_color: tagColor,
        })
        return data
    } catch(error){
        throw error
    }
}

export const updateTag =  async ({
    tagName,
    tagColor,
    tag_id,
}, token) => {
    try {
        const data =  await postCF(`/update_tag`, token, {
            tag_name: tagName,
            tag_color: tagColor,
            id: tag_id,
        })
        return data
    } catch(error){
        throw error
    }
}

export const deleteTag = async ({
    tag_id, 
}, token) => {
    try {
        return await postCF(`/delete_tag`, token, {
            tag_id,
        })
    } catch(error){
        throw error
    }
}

export const createPostTags = async (tagids, token) => {
    try {
        return await postCF(`/create_post_tag`, token, tagids)
    } catch(error){
        throw error
    }
}

export const deletePostTag = async ({
    tag_id, 
    post_id,
}, token) => {
    try {
        return await postCF(`/delete_post_tag`, token, {
            tag_id, 
            post_id,
        })
    } catch(error){
        throw error
    }
}

export const getPostsTag = async (tag_id, token) => {
    try {
        return await postCF(`/all_tag_posts`, token, {tag_id})
    } catch(error){
        throw error
    }
}

export const getTagsPosts = async ({tag_id}, token) => {
    try {
        return await postCF('/all_posts_tag_page', token, {
            tag_id,
            page: 0,
        })
        
    } catch(error){
        throw error
    }
}
