import React from 'react'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

import { GoogleLoginButton } from "react-social-login-buttons"

import LoginHeader  from '../Login/LoginHeader'
import LoginHelp from '../Login/LoginHelp'
import Copyright from '../Login/Copyright'
import LoginInput from '../Inputs/LoginInput'
import EmailIcon from '@mui/icons-material/Email';
import LoadingIconButton from '../Buttons/LoadingIconButton'

export default function SignInPage({
    inputs, 
    loginPassword, 
    googleLogin,
    alert,
    loading,
}){

  const buttonItems = [
    {
      title: 'Email Sign In',
      icon: <EmailIcon />,
      onClick: loginPassword,
    },
  ]

  return (
      <Container component="main" maxWidth="xs">
        <Paper sx={{ padding: 2, marginTop: 2, backgroundColor: 'rgb(240, 246, 255)' }}> 
          <Grid container 
            justifyContent='center' 
            textAlign='center'
            spacing={2}
          >
            <Grid item xs={12}>
              <LoginHeader title='Sign In' />
            </Grid>  
            {inputs.map(l => 
              <Grid item xs={12} key={l.name} >
                <LoginInput {...l} error={alert} />
              </Grid>
            )}
            {buttonItems.map(b => 
              <Grid item xs={12} key={b.title}>
                <LoadingIconButton 
                  size='large'
                  {...b}
                  loading={loading}
                />
              </Grid>
            )}
             <Grid item xs={12}>
                <Divider />
            </Grid>  
            <Grid item xs={12} >
              <GoogleLoginButton  
                onClick={googleLogin}
                disabled={loading}
              />
            </Grid>
          </Grid>
            <Box mt={3}>
              <LoginHelp />
            </Box>
          <Box mt={3}>
              <Copyright />
          </Box>
        </Paper>
      </Container>
  )
}