import React, {useState, useEffect} from "react"
import {auth} from '../firebase'

// packages
import { useParams, useHistory } from "react-router-dom"

// queries
import {
  getTags, 
  getPostsTag,
} from '../queries/tagsQueries'

// UI

import {Alert, CircularProgress} from "@mui/material"

// custom componets
import PostListPage from '../components/Pages/PostListPage'

export default function TagPostList(){

    const { 
      tag_id, 
    } = useParams()

    const history = useHistory()

    const [ posts, setPosts ] = useState([])
    const [ tags, setTags ] = useState([])
    const [ tagName, setTagName ] = useState([])
    const [ tagColor, setTagColor ] = useState([])
    const [isLoading, setLoading ] = useState(false)
    const [isError, setisError ] = useState(false)
    const [ currentUser, setCurrentUser] = useState()

    useEffect(() => {
      async function getData(currentUser){
        
        try {
          const data = await getPostsTag(tag_id, currentUser.accessToken)
          if(data){
            const{ posts } = data
            setPosts(posts)
            const tags = posts[0].post_tags.filter(t => t.tag_id===parseInt(tag_id))
            if(tags.length>0){
              setTagName(tags[0].tag_name)
              setTagColor(tags[0].tag_color)
            }
           
          }

          const tags = await getTags(currentUser.accessToken)
          setTags(tags)

        } catch(error){
          setisError(true)
        } finally {
          setLoading(false)
        }
      }

      if(currentUser){
        setLoading(true)
        getData(currentUser)
      } 
      const unsubscribe = auth.onAuthStateChanged(user => {
        if(user){
          setCurrentUser(user)
        } else {
          history.push('signin')
        }
      })
      return unsubscribe
    }, [tag_id, currentUser])

    if(isLoading) return <CircularProgress />
    if(!isLoading && isError)  return <Alert severity="error" >Loading error!</Alert>
    const previewUrl = `/tags_preview/${tag_id}`

    return <PostListPage
            tagColor={tagColor}
            title='Posts'
            posts={posts}
            tags={tags}
            authorName={tagName}
            previewUrl={previewUrl}
          />
}