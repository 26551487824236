import React, {useState, useEffect} from "react"
import {auth} from '../firebase'

// packages
import { useParams, useHistory } from "react-router-dom"

// queries
import {
  getTags, 
  getPostsAuthor,
} from '../queries/blogQueries'

// UI

import {Alert, CircularProgress} from "@mui/material"

// custom componets
import PostListPage from '../components/Pages/PostListPage'

export default function AuthorPostList(){

    const { 
      author_id, 
    } = useParams()

    const history = useHistory()

    const [ posts, setPosts ] = useState([])
    const [ tags, setTags ] = useState([])
    const [ authorName, setAuthorName ] = useState([])
    const [isLoading, setLoading ] = useState(false)
    const [isError, setisError ] = useState(false)
    const [ currentUser, setCurrentUser] = useState()

    useEffect(() => {
      async function getData(currentUser){
        
        try {
          const data = await getPostsAuthor(author_id, currentUser.accessToken)
      
          if(data){
            const{ posts } = data
            const authorName = posts[0].author_name
            setPosts(posts)
            if(authorName){
              setAuthorName(authorName)
            }
           
          }

          const tags = await getTags(currentUser.accessToken)
          setTags(tags)

        } catch(error){
          setisError(true)
        } finally {
          setLoading(false)
        }
      }

      if(currentUser){
        setLoading(true)
        getData(currentUser)
      } 
      const unsubscribe = auth.onAuthStateChanged(user => {
        if(user){
          setCurrentUser(user)
        } else {
          history.push('signin')
        }
      })
      return unsubscribe
    }, [author_id, currentUser])

    if(isLoading) return <CircularProgress />
    if(!isLoading && isError)  return <Alert severity="error" >Loading error!</Alert>

    const previewUrl = `/author_preview/${author_id}`
  
    return <PostListPage
            title='Posts'
            posts={posts}
            tags={tags}
            authorName={authorName}
            previewUrl={previewUrl}
          />
}