import React from 'react'

// mui
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'

// custom components
import TagsHeader from './TagsHeader'

import { Divider } from '@mui/material'

const BlogHeader = ({
    siteName,
    siteLogo,
    tags
}) => {
   

    return <Grid container 
                justifyContent='space-between' 
                alignItems='center'
                spacing={1}
                sx={{mt: 1}}
            >
                <Grid item>
                    <Grid container 
                        alignItems='center'
                        spacing={1}
                    >
                        <Grid item>
                            <picture >
                                <source srcSet={siteLogo}  />
                                <img src={siteLogo}
                                    height='24'
                                    width='24'
                                    style={{
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        borderRadius: '10%',
                                    }}
                                    alt="langalogo" 
                                />
                            </picture>
                        </Grid>
                        <Grid item>
                            <Link
                                href='/'
                                variant="h6"
                                sx={{color: '#6415ff'}}
                            >
                                {siteName}
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <TagsHeader 
                        tags={tags}
                    />
                </Grid>
            </Grid>
}

export default BlogHeader