import React from 'react';
import moment from 'moment'

import Grid from '@mui/material/Grid';
import  Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import {  Box  } from '@mui/material';
import { IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const MainItem = ({
  title,
  published_date,
  summary,
}) => 
  <Grid item xs={12} >
    <Typography variant="h5" color='#6415ff'> 
      <Link   >
        {title}
      </Link>
    </Typography>
    <Typography variant="caption">
      {moment(published_date).format('MMMM Do, YYYY')} 
    </Typography>
    <Typography variant="body1" xs={12} color='textSecondary'>
      {summary}
    </Typography>
  </Grid>


export default function Main({
    posts,
    curTag,
    page_num,
    total_count,
}) {

  const name = curTag ? curTag.tag_name  : ''
  const title =  curTag ? `${name} Articles` : ''
  const curNum = parseInt(page_num)-1
  const total = Math.round(total_count/10)
  const tot = curNum*10
  const more = posts.length>10 || total_count>tot
  const size = 24

  return (
    <Grid
      item
      xs={12}
      md={8}
    > 
      <Grid container alignItems='center' spacing={2} justifyContent='space-between'>
      <Grid item>
        <Grid container alignItems='center' spacing={2}>
          {curTag &&
          <Grid item>
            <Box sx={{
              backgroundColor: curTag.tag_color, 
              width: size, height: size,
              borderRadius:1, 
              mr:1,
            }} />
          </Grid>
        }
        {curTag &&
          <Grid item>
            <Typography variant="h5" 
               color={curTag.tag_color}
              >
              {title}
            </Typography>      
            </Grid>
          }
          </Grid> 
          
        </Grid>
        <Grid item>
        {total>0 &&
          <Grid container alignItems='center' spacing={2} justifyContent='space-between'>
            <Grid item>
              <Link  >
                <IconButton disabled={curNum===1} color='primary' >
                  <ChevronLeftIcon />
                </IconButton>
              </Link>   
            </Grid> 
            <Grid item>
              <Typography>
                {curNum} / {total}
              </Typography>
            </Grid> 
            <Grid item>
              <Link  >
                <IconButton disabled={!more} color='primary'>
                  <ChevronRightIcon  />
                </IconButton>
              </Link>    
            </Grid> 
          </Grid>
        }
        </Grid>
      </Grid>
      <Divider sx={{mt:2, mb:2}} />
      <Grid container  spacing={3}>
      {posts.map((p) => 
        <MainItem {...p} key={p.title} />
      )}
      </Grid>
    </Grid>
  );
}
