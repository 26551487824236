import React from 'react'
import FadeIn from 'react-fade-in'
// mui components
import Grid from '@mui/material/Grid'

// custom components
import SnackAlert from '../Dialogs/SnackAlert'
import UpdateSite from '../Admin/UpdateSite'
import UpdateSocials from '../Admin/UpdateSocials'

export default function AdminPage({
  msgType,
  alertMsg,
  snack,
  loading,
  siteName1,
  adminEmail1,
  description1,
  twitter,
  facebook,
  handleClose,
  handleSiteName,
  handleAdminEmail,
  handleDescription,
  handleFacebook,
  handleTwitter,
  updateSite,
  handleInstagram,
  handleDiscord,
  handleTiktok,
  handleYoutube,
  instagram,
  discord,
  tiktok,
  youtube,
  handleSiteLogo,
  siteLogo,
  baseUrl,
  handleBaseUrl,
  updateSiteSocials,
}){
    return(
      <FadeIn delay={100}>
      <Grid container spacing={2}>
            <Grid item xs={12} lg={6}> 
              <UpdateSite
                siteName1={siteName1}
                description1={description1}
                adminEmail1={adminEmail1}
                twitter={twitter}
                facebook={facebook}
                handleSiteName={handleSiteName}
                handleAdminEmail={handleAdminEmail}
                handleDescription={handleDescription}
                updateSite={updateSite}
                loading={loading}
                handleSiteLogo={handleSiteLogo}
                siteLogo={siteLogo}
                baseUrl={baseUrl}
                handleBaseUrl={handleBaseUrl}
              />
            </Grid> 
            <Grid item xs={12} lg={6}> 
              <UpdateSocials
                twitter={twitter}
                facebook={facebook}
                handleTwitter={handleTwitter}
                handleFacebook={handleFacebook}
                updateSite={updateSiteSocials}
                loading={loading}
                handleInstagram={handleInstagram}
                handleDiscord={handleDiscord}
                handleTiktok={handleTiktok}
                handleYoutube={handleYoutube}
                instagram={instagram}
                discord={discord}
                tiktok={tiktok}
                youtube={youtube}
              />
            </Grid> 
            <SnackAlert 
              snack={snack}
              handleClose={handleClose}
              msgType={msgType}
              alertMsg={alertMsg}
            />
          </Grid>
      </FadeIn>
  )
}