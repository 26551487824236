import  React from 'react'
import { Link } from 'react-router-dom'
// UI

// Mui
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'

// icons
import AccountCircle from '@mui/icons-material/AccountCircle'

// custom components
import SnackAlert from '../Dialogs/SnackAlert'
import AppBarButton from '../Buttons/AppBarButton'
import Logo from '../ImageElements/Logo'

const ResponsiveAppBarComponents = ({
    buttons,
    profileButtons,
    anchorElNav,
    anchorProfileElNav,
    alertMsg,
    section2,
    msgType,
    snack,
    handleClose,
    handleOpenNavMenu,
    handleCloseNavMenu,
    handleOpenProfileMenu,
    handleCloseProfileMenu,
    logout,
}) => {
    const site = localStorage.getItem('site_name')
    const siteName = site ? site : 'Name Site'
    return (
    <AppBar position="static" sx={{mb:2}}>
        <Container maxWidth="xl">
            <Toolbar disableGutters>  
                <Box sx={{ 
                            display: { 
                                xs: 'none', 
                                lg: 'flex',
                            }, 
                            mr: 1,
                 }}>
                    <Logo size={3}  />
                </Box>
                <Box sx={{ 
                            display: { 
                                xs: 'none', 
                                lg: 'flex',
                            }, 
                            mr: 1,
                 }}>
                    <Typography >
                        {siteName}
                    </Typography>
                </Box>
                <Box sx={{ 
                            display: { 
                                xs: 'none', 
                                lg: 'flex',
                            }, 
                            mr: 1,
                 }}>
                    <Typography >
                        |
                    </Typography>
                   
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
                    <IconButton
                        size="large"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', lg: 'none' },
                        }}
                    >
                        {section2.map((p) => (
                            <MenuItem 
                                key={p.title}   
                                component={Link}
                                to={p.href} 
                                style={{ textDecoration: 'none', display: 'block' }}
                            >
                                {p.title}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                <Link to='/user/dashboard' style={{textDecoration: 'none'}} >
                    <Box sx={{ 
                        display: { 
                            xs: 'flex', 
                            lg: 'none',
                        }, 
                        mr: 2, 
                        pt: .25, 
                        pb:.25, 
                        pr: 1, 
                        pl: 1, 
                       }}
                    >  
                    
                        <Logo size={3}  />
                </Box>
                </Link>
                <Typography
                    noWrap
                    sx={{
                        mr: 2,
                        display: { xs: 'flex', lg: 'none' },
                        flexGrow: 1
 ,                      textDecoration: 'none',
                    }}
                    >
                    {siteName}
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}> 
                    {buttons.map(b => 
                        <AppBarButton {...b} key={b.title} />
                    )} 
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                    <IconButton
                        size="large"
                        onClick={handleOpenProfileMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="profile-appbar"
                        anchorEl={anchorProfileElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorProfileElNav)}
                        onClose={handleCloseProfileMenu}
                    >
                        {profileButtons.map((p) => (
                            <MenuItem 
                                key={p.title}   
                                component={Link}
                                to={p.link} 
                                style={{ textDecoration: 'none', display: 'block' }}
                            >
                                {p.title}
                            </MenuItem>
                        ))}
                            <MenuItem 
                                component="a"
                                onClick={logout}
                                style={{ textDecoration: 'none', display: 'block' }}
                            >
                                Logout
                            </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </Container>
        <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
        /> 
    </AppBar>
    )
}

export default ResponsiveAppBarComponents