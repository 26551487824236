import * as React from 'react';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

import MainCategory from '../Blog/MainCategory';
import {SidebarPlain} from '../Blog/SideBar';
import BlogHeader from '../Blog/BlogHeader'
import Footer from '../Blog/Footer'

export default function CategoryPage({
  posts,
  site,
  tags,
  curTag,
  page_num,
  tag_id,
  total_count,
}) {
  const siteName = site ? site.site_name : ''
  const siteLogo = site ? site.site_logo : ''
  const siteDescription = site ? site.description : ''
  const siteTwitter = site ? site.twitter : ''
  const siteFacebook = site ? site.facebook : ''
  const sitediscord = site ? site.discord : ''
  const siteyoutube = site ? site.youtube : ''
  const siteinstagram = site ? site.instagram : ''
  const adminEmail = site ? site.admin_email : ''

      return  <Container maxWidth="lg">
                <BlogHeader 
                  siteName={siteName} 
                  siteLogo={siteLogo}
                  tags={tags}
                />
                <Divider sx={{mt: 2, mb: 2}} /> 
                <Grid container spacing={2}  >
                  <MainCategory 
                    curTag={curTag} 
                    posts={posts} 
                    page_num={page_num}
                    tag_id={tag_id}
                    total_count={total_count}
                  />
                  <SidebarPlain
                    siteDescription={siteDescription}
                    siteTwitter={siteTwitter}
                    siteFacebook={siteFacebook}
                    siteinstagram={siteinstagram}
                    sitediscord={sitediscord}
                    siteyoutube={siteyoutube}
                    adminEmail={adminEmail}
                  />
                </Grid>
                <Footer siteName={siteName} /> 
              </Container>
}