import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyChTj5s2kbubgx_12FHuM9xd63kM70ay9Q",
  authDomain: "cf-blog-fdfdc.firebaseapp.com",
  projectId: "cf-blog-fdfdc",
  storageBucket: "cf-blog-fdfdc.appspot.com",
  messagingSenderId: "1028664329700",
  appId: "1:1028664329700:web:1085d5822c8cc6f50fb3f1",
  measurementId: "G-KXYQY35HBE"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app)